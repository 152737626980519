import { apiCall } from "../common/NetworkCalls";
import { API_ENDPOINTS } from "../constants/api-endpoints";
import { MESSAGES } from "../constants/messages";

export const USER_LOGGED_IN = 'user_logged_in';
export const USER_FAILED_TO_LOGIN = 'user_failed_to_login';

function userFailedToLogin(message){
    return {
        type: USER_FAILED_TO_LOGIN, 
        payload: message
    }
}

function userLoggedIn(userInfo){
    return {
        type: USER_LOGGED_IN, 
        payload: userInfo
    }
}

export function loginUser(reqData){
    return (dispatch) => {
        return apiCall(API_ENDPOINTS.LOGIN, 'POST', reqData).then((response) => {
            if(response.status.toString() === '200'){
                dispatch(userLoggedIn(response.data));
                return true;
            }else{
                dispatch(userFailedToLogin(response.message));
                return false;
            }
        }).catch((error) => {
            dispatch(userFailedToLogin(MESSAGES.SOMETHING_WRONG));
            return false;
        })
    }
}