import React from 'react';
import Step1 from '../assets/images/Step1.svg';
import Arrow1 from '../assets/images/Arrow1.svg';
import Arrow2 from '../assets/images/Arrow2.svg';
import Step2 from '../assets/images/Step2.svg';
import MobileStep1 from '../assets/images/MobileStep1.svg';
import MobileStep2 from '../assets/images/MobileStep2.svg';
import Step3 from '../assets/images/Step3-AI.svg';
import Arrow3 from '../assets/images/Arrow3.svg';
import Step4 from '../assets/images/Step4.svg';
import Step5 from '../assets/images/Step5.svg';
import Arrow4 from '../assets/images/Arrow4.svg';
import Mobile_Arrow from '../assets/images/ArrowForMobileVersion.svg';
import shape_19 from '../assets/images/shape-19.png';
import shape_20 from '../assets/images/shape-20.png';
import shape_21 from '../assets/images/shape-21.png';
import shape_22 from '../assets/images/shape-22.png';
import shape_23 from '../assets/images/shape-23.png';
import shape_24 from '../assets/images/shape-24.png';
import shape12 from '../assets/images/shape-12.png';
//Circle
import circle1 from '../assets/images/Circle/Animations_Circle1.svg';
import circle2 from '../assets/images/Circle/Animations_Circle2.svg';
import circle3 from '../assets/images/Circle/Animations_Circle3.svg';
import circle4 from '../assets/images/Circle/Animations_Circle4.svg';
import circle5 from '../assets/images/Circle/Animations_Circle5.svg';
import circle6 from '../assets/images/Circle/Animations_Circle6.svg';
import circle7 from '../assets/images/Circle/Animations_Circle7.svg';
import circle8 from '../assets/images/Circle/Animations_Circle8.svg';
import circle9 from '../assets/images/Circle/Animations_Circle9.svg';
import circle10 from '../assets/images/Circle/Animations_Circle10.svg';
import circle11 from '../assets/images/Circle/Animations_Circle11.svg';
import circle12 from '../assets/images/Circle/Animations_Circle12.svg';
import circle13 from '../assets/images/Circle/Animations_Circle13.svg';

//Clock
import clock1 from '../assets/images/AnimationClock/Animations_Clock1.svg';
import clock2 from '../assets/images/AnimationClock/Animations_Clock2.svg';
import clock3 from '../assets/images/AnimationClock/Animations_Clock3.svg';
import clock4 from '../assets/images/AnimationClock/Animations_Clock4.svg';
import clock5 from '../assets/images/AnimationClock/Animations_Clock5.svg';
import clock6 from '../assets/images/AnimationClock/Animations_Clock6.svg';

//Bar
import bar0 from '../assets/images/Bar/Animations_Bar0.svg';
import bar1 from '../assets/images/Bar/Animations_Bar1.svg';
import bar2 from '../assets/images/Bar/Animations_Bar2.svg';
import bar3 from '../assets/images/Bar/Animations_Bar3.svg';
import bar4 from '../assets/images/Bar/Animations_Bar4.svg';
import bar5 from '../assets/images/Bar/Animations_Bar5.svg';
import bar6 from '../assets/images/Bar/Animations_Bar6.svg';
import bar7 from '../assets/images/Bar/Animations_Bar7.svg';
import bar8 from '../assets/images/Bar/Animations_Bar8.svg';
import bar9 from '../assets/images/Bar/Animations_Bar9.svg';
import bar10 from '../assets/images/Bar/Animations_Bar10.svg';
import bar11 from '../assets/images/Bar/Animations_Bar11.svg';
import bar12 from '../assets/images/Bar/Animations_Bar12.svg';
import bar13 from '../assets/images/Bar/Animations_Bar13.svg';
import bar14 from '../assets/images/Bar/Animations_Bar14.svg';
import bar15 from '../assets/images/Bar/Animations_Bar15.svg';

//Cog
import cog1 from '../assets/images/cog/Animations_Cog1.svg';
import cog2 from '../assets/images/cog/Animations_Cog2.svg';
import cog3 from '../assets/images/cog/Animations_Cog3.svg';
import cog4 from '../assets/images/cog/Animations_Cog4.svg';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SubNavigation from '../components/Sub-navigation';

import { getFromStorage, removeFromStorage } from '../common/service';
import { CONSTANTS } from '../constants';
import { API_ENDPOINTS, AUTH_HEADER_KEY, PLAIN_HEADERS } from '../constants/api-endpoints';
import { apiCall } from '../common/NetworkCalls';
import { withRouter } from 'react-router-dom';

class AiPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserLoggedIn: false,
            userInfo: ''
        }
    }

    componentDidMount() {
        var userData = getFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        console.log(userData)
        if (userData) {
            userData = JSON.parse(userData);
            this.setState({ isUserLoggedIn: true, userInfo: userData });
        }
    }

    logOutUser() {
        this.setState({ isUserLoggedIn: false, userInfo: '' });
        removeFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        var headers = PLAIN_HEADERS;
        headers[AUTH_HEADER_KEY] = `Bearer ${this.state.userInfo.authToken}`;
        return apiCall(API_ENDPOINTS.LOGOUT, 'GET', '',).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {

        return (
            <div className="trusteddev-tab AI-page">
                <Header
                    isUserLoggedIn={this.state.isUserLoggedIn}
                    userInfo={this.state.userInfo}
                    logOutUser={() => {
                        this.logOutUser()
                    }}
                />
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active trusteddev-tab" id="trustedDev" role="tabpanel" aria-labelledby="trustedDev-tab">
                        <div className="AI-page-BackgroundColor">
                            <div className="AI-page-banner">
                                <SubNavigation
                                    isUserLoggedIn={this.state.isUserLoggedIn}
                                    userInfo={this.state.userInfo}
                                    logOutUser={() => {
                                        this.logOutUser()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                        <div className="card text-center card_border card-margin">
                            <div className="card-header card_border bg_sky_blue">
                                <div className="offset-xl-3 col-xl-8 offset-lg-3 col-lg-8 offset-md-3 col-md-8 col-sm-12 p-0">
                                    <h2 className="AI-trustedDev">
                                    Pre-vetting Software Engineers with AI + Machine Learning and <span className="text-style">Passionate Humans</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row no-gutters">
                                    <div className="offset-xl-3 col-xl-8 offset-lg-3 col-lg-8 offset-md-3 col-md-8 col-sm-12">
                                        <div className="row no-gutters">
                                            <div className="col-md-12">
                                                <p className="AI-content">                                                                             
                                                Trusted Dev is designed to <span className="text-style">  improve the enterprise information technology recruitment life cycle  </span> by integrating <span className="text-style"> passionate humans, AI and machine learning </span><span className="text-style">  to match enterprises with</span> <span> pre-vetted software engineers and CTO caliber talent.</span>
                                                </p>
                                            </div>
                                            <div className="col-md-12">
                                                <p className="AI-paragraph">Rapid technological innovations in the field of AI and machine learning are changing the way that enterprise talent acquisition teams are sourcing software engineers and other technical roles.</p>
                                                <p className="AI-paragraph">Recent advancements in AI have made it possible for enterprise hiring managers to increase the likelihood that the best hiring decisions are being made with the data available.</p>
                                                <p className="AI-paragraph">Trusted Dev is committed to <span className="text-style">leveraging AI and machine learning to reduce enterprise talent acquisition costs, increase enterprise talent acquisition team productivity and reduce overall enterprise risks associated with the software development recruitment lifecycle. We employ passionate R&D processes to ensure that we stay at the forefront of </span> modern technology capabilities.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters justify-content-end">
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="card-header AI-how-it-work-border">
                                <h2 className="How-works">
                                The Trusted Dev Methodology
                            </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-section">
                    <div className="row no-gutters">
                        <div className="col-md-12 step-section">
                            {/* step 1 */}
                            <img src={Step1} alt="" className="d-none d-lg-block d-xl-block step-img step-img-step1" />
                            <img src={MobileStep1} alt="" className="d-lg-none d-xl-none step-img step-img-step1" />
                            <span className="step-label step-label-step1">STEP</span>
                            <p className="step-content step-content-step1">
                            A technical and behavioral assessment is administered to pre-vet software engineering and CTO candidates.
                        </p>
                            <img src={Arrow1} alt="" className="step-arrow step-arrow-step1" />
                            <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step1" />
                            {/* step 2 */}
                            <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step2" />
                            <img src={Arrow2} alt="" className="step-arrow step-arrow-step2" />
                            <span className="step-label step-label-step2">STEP</span>
                            <p className="step-content step-content-step2">
                                A video call interview is conducted with the pre-vetted candidate to further gauge communication and culture fit.
                            </p>
                            <img src={Step2} className="d-none d-lg-block d-xl-block step-img step-img-step2" alt="" />
                            <img src={MobileStep2} className="d-lg-none d-xl-none step-img step-img-step2" alt="" />
                            {/* step 3 */}
                            <p className="step-content step-content-step3">
                                Your team interviews the pre-vetted candidate.
                        </p>
                            <span className="step-label step-label-step3">STEP</span>
                            <img src={Step3} className="step-img step-img-step3" alt="" />
                            <img src={Arrow3} className="step-arrow step-arrow-step3" alt="" />
                            <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step3" />
                            {/* step 4 */}
                            <span className="step-label step-label-step4">STEP</span>
                            <img src={Step4} className="step-img step-img-step4" alt="" />
                            <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step4" />
                            <p className="step-content step-content-step4">
                                We curate pre-vetted talent and you decide who joins your internal team.
                        </p>
                            <img src={Arrow4} className="step-arrow step-arrow-step4" alt="" />
                            {/* step 5 */}
                            <img src={Step5} className="step-img step-img-step5" alt="" />
                            <span className="step-label step-label-step5">STEP</span>
                            <p className="step-content step-content-step5">
                                Humans and AI in the branch of machine learning work together to optimize your software development recruitment life cycle and overall internal team talent quality.
                        </p>
                        </div>
                    </div>
                </div>
                <div className="page_divider_banner">
                    <div className="row no-gutters justify-content-start bg-color Enterprise-Benefits wow animate__animated animate__fadeInLeft" data-wow-delay=".1s">
                        <div className="col-xl-10 col-lg-11 col-md-11 page_divider hide_under_575 d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
                            <div className="row">
                                <div className="offset-xl-2 col-xl-10 offset-lg-1 col-lg-11 offset-md-2 col-md-10 col-sm-12">
                                    <h3 className="text-center The-Implementation-of-AI-With-Software-Development-Resource-Vet wow animate__animated animate__fadeIn" data-wow-delay=".2s">
                                        The Enterprise Benefits of AI With Machine Learning
                                </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 page_divider d-xs-block d-sm-block d-md-none d-lg-none d-xl-none">
                            <div className="row no-gutters">
                                <h3 className="text-center The-Implementation-of-AI-With-Software-Development-Resource-Vet wow animate__animated animate__fadeIn" data-wow-delay=".2s">The Enterprise Benefits of AI With Machine Learning </h3>
                            </div>
                        </div>
                    </div>
                    <div className="page_divider_banner">
                        <div className="row no-gutters justify-content-center wow animate__fadeInUpBig animate__animated" data-wow-delay=".1s">
                            <div className="col-xl-8 col-lg-10 col-md-10 mt-4 col-sm-12 col-xs-12">
                                <div className="row no-gutters">
                                    <div className="col-6 right_cut_corners bg_white">
                                        <div className="row no-gutters bg_white padding24 px-3 Rectangle-box">
                                            <div className="col-md-3 d-flex align-items-center justify-content-center">
                                                <div className="position-relative">
                                                    <div class="Animations_Circle wow animate__bounceIn animate__animated" data-wow-delay=".8s">
                                                        <img src={circle1} className="position-absolute" alt="" id="frame1" />
                                                        <img src={circle2} className="position-absolute" alt="" id="frame2" />
                                                        <img src={circle3} className="position-absolute" alt="" id="frame3" />
                                                        <img src={circle4} className="position-absolute" alt="" id="frame4" />
                                                        <img src={circle5} className="position-absolute" alt="" id="frame5" />
                                                        <img src={circle6} className="position-absolute" alt="" id="frame6" />
                                                        <img src={circle7} className="position-absolute" alt="" id="frame7" />
                                                        <img src={circle8} className="position-absolute" alt="" id="frame8" />
                                                        <img src={circle9} className="position-absolute" alt="" id="frame9" />
                                                        <img src={circle10} className="position-absolute" alt="" id="frame10" />
                                                        <img src={circle11} className="position-absolute" alt="" id="frame11" />
                                                        <img src={circle12} className="position-absolute" alt="" id="frame12" />
                                                        <img src={circle13} className="position-absolute" alt="" id="frame13" />
                                                    </div>
                                                </div>
                                                <h3 className="d-md-none d-lg-none d-xl-none text-left ml-3 digit-96 wow animate__fadeIn animate__animated" data-wow-delay=".9s">96%</h3>
                                            </div>
                                            <div className="col-md-9 wow animate__fadeIn animate__animated" data-wow-delay=".9s">
                                                <h3 className="d-none d-md-block d-lg-block d-xl-block text-left color_color2 digit-96">96%</h3>
                                                <p className="Belive-AI-content center_content">
                                                    Believe AI can greatly enhance <br />talent acquisition and retention. <small>[1]</small>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 left_cut_corners bg_color2">
                                        <div className="row no-gutters bg_color2 padding24 px-3 Rectangle-box">
                                            <div className="col-md-9 text-right">
                                                <div className="center_icon_heading">
                                                    <h3 className="color_color2 hour24-content mr-3 wow animate__fadeIn animate__animated" data-wow-delay=".9s">14 hours</h3>
                                                    <div className="position-relative d-sm-block d-md-none d-lg-none d-xl-none ">
                                                        <div class="Animations_Clock wow animate__bounceIn animate__animated" data-wow-delay=".8s">
                                                            <img src={clock1} alt="" class="Animations_Clock" id="frame1" />
                                                            <img src={clock2} alt="" class="Animations_Clock" id="frame2" />
                                                            <img src={clock3} alt="" class="Animations_Clock" id="frame3" />
                                                            <img src={clock4} alt="" class="Animations_Clock" id="frame4" />
                                                            <img src={clock5} alt="" class="Animations_Clock" id="frame5" />
                                                            <img src={clock6} alt="" class="Animations_Clock" id="frame6" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="Average-amount-content text-align wow animate__fadeIn animate__animated" data-wow-delay=".9s">
                                                    Average amount of time per week <br /><span className="text-style">lost to manually</span> completing tasks.<small> [1]</small>
                                                </p>
                                            </div>
                                            <div className="col-md-3 d-flex align-items-center justify-content-center">
                                                <div className="position-relative d-none d-md-block d-lg-block d-xl-block">
                                                    <div class="Animations_Clock wow animate__bounceIn animate__animated" data-wow-delay=".8s">
                                                        <img src={clock1} alt="" class="position-absolute" id="frame1" />
                                                        <img src={clock2} alt="" class="position-absolute" id="frame2" />
                                                        <img src={clock3} alt="" class="position-absolute" id="frame3" />
                                                        <img src={clock4} alt="" class="position-absolute" id="frame4" />
                                                        <img src={clock5} alt="" class="position-absolute" id="frame5" />
                                                        <img src={clock6} alt="" class="position-absolute" id="frame6" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="white-box wow animate__bounceIn animate__faster animate__animated" data-wow-delay=".5s"></div>
                                    <div className="blue-box wow animate__bounceIn animate__faster animate__animated" data-wow-delay=".5s"></div>
                                    <img src={shape12} className="four_box_center_img wow animate__faster animate__bounceIn animate__animated" alt="" data-wow-delay=".6s" />
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-6 bg_color2">
                                        <div className="row no-gutters bg_color2 padding24 px-3 Rectangle-box">
                                            <div className="col-md-3 d-flex align-items-center justify-content-center">
                                                <div className="position-relative">
                                                    <div class="Animations_Bar wow animate__bounceIn animate__animated" data-wow-delay=".8s" >
                                                        <img src={bar0} alt="" className="position-absolute" id="frame0" />
                                                        <img src={bar1} alt="" className="position-absolute" id="frame1" />
                                                        <img src={bar2} alt="" className="position-absolute" id="frame2" />
                                                        <img src={bar3} alt="" className="position-absolute" id="frame3" />
                                                        <img src={bar4} alt="" className="position-absolute" id="frame4" />
                                                        <img src={bar5} alt="" className="position-absolute" id="frame5" />
                                                        <img src={bar6} alt="" className="position-absolute" id="frame6" />
                                                        <img src={bar7} alt="" className="position-absolute" id="frame7" />
                                                        <img src={bar8} alt="" className="position-absolute" id="frame8" />
                                                        <img src={bar9} alt="" className="position-absolute" id="frame9" />
                                                        <img src={bar10} alt="" className="position-absolute" id="frame10" />
                                                        <img src={bar11} alt="" className="position-absolute" id="frame11" />
                                                        <img src={bar12} alt="" className="position-absolute" id="frame12" />
                                                        <img src={bar13} alt="" className="position-absolute" id="frame13" />
                                                        <img src={bar14} alt="" className="position-absolute" id="frame14" />
                                                        <img src={bar15} alt="" className="position-absolute" id="frame15" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9 center_content wow animate__fadeIn animate__animated" data-wow-delay=".9s">
                                                <h3 className="text-left cost-of-not-automating-content"><span className="color_color1">Costs </span>of Not Automating</h3>
                                                <ul className="list_type_none padding0">
                                                    <li className="list-item-content"><span className="text-style">41%</span> Lower Productivity</li>
                                                    <li className="list-item-content"><span className="text-style">35%</span> Higher Costs</li>
                                                    <li className="list-item-content"><span className="text-style">17%</span> Poor Candidate Experience <small>[1]</small></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 bg_white">
                                        <div className="row no-gutters bg_white padding24 px-3 Rectangle-box">
                                            <div className="col-md-9 col-sm-9  col-xs-9 text-right wow animate__fadeIn animate__animated" data-wow-delay=".9s">
                                                <h3 className="what-can-be-automated-content">What Can Be Automated?</h3>
                                                <ul className="list_type_none padding0 d-none d-md-block d-lg-block d-xl-block">
                                                    <li className="percentage-line-content"><span className="text-style color_color1">100%</span> Sourcing, Screening {'&'} Matching</li>
                                                    <li className="percentage-line-content"><span className="text-style color_color7">20%</span> Candidate {'&'} Hiring Relation <small>[1]</small></li>
                                                </ul>
                                            </div>
                                            <div className="d-none d-md-block d-lg-block d-xl-block">
                                                <div className="col-md-3 col-sm-3  col-xs-3 d-flex align-items-center justify-content-center">
                                                    <div className="position-relative">
                                                        <div class="Animations_Cog wow animate__bounceIn animate__animated" data-wow-delay=".8s">
                                                            <img src={cog1} alt="" className="position-absolute" id="frame1" />
                                                            <img src={cog2} alt="" className="position-absolute" id="frame2" />
                                                            <img src={cog3} alt="" className="position-absolute" id="frame3" />
                                                            <img src={cog4} alt="" className="position-absolute" id="frame4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                                                <div className="d-flex">
                                                    <ul className="list_type_none padding0 wow animate__fadeIn animate__animated" data-wow-delay=".9s">
                                                        <li className="percentage-line-content"><span className="text-style color_color1">100%</span> Sourcing, Screening {'&'} Matching</li>
                                                        <li className="percentage-line-content"><span className="text-style color_color7">20%</span> Candidate {'&'} Hiring Relation <small>[1]</small></li>
                                                    </ul>
                                                    <div className="position-relative">
                                                        <div class="Animations_Cog wow animate__bounceIn animate__animated" data-wow-delay=".8s">
                                                            <img src={cog1} alt="" className="position-absolute" id="frame1" />
                                                            <img src={cog2} alt="" className="position-absolute" id="frame2" />
                                                            <img src={cog3} alt="" className="position-absolute" id="frame3" />
                                                            <img src={cog4} alt="" className="position-absolute" id="frame4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="Revealing-Stats-label">
                                    [1] 12 Revealing Stats On How Recruiters Feel About AI, Ji-A Min <a href="https://ideal.com/how-recruiters-feel-about-ai/" target="_blank" rel="noreferrer" >https://ideal.com/how-recruiters-feel-about-ai/</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <h3 className="Proud-Member-of-the-Following-Organizations p-4">Proud <span className="color_color1">Member</span> of the Following Organizations:</h3>
                        <div className="row no-gutters justify-content-start">
                            <div className="col-xl-10 col-lg-10 col-md-10 logo_divider_row d-none d-md-block d-lg-block d-xl-block">
                                <div className="row justify-content-end">
                                    <div className="offset-xl-2 col-xl-push-8 offset-lg-3 col-lg-9  offset-md-3 col-md-9 d-flex justify-content-center align-items-center">
                                        <img src={shape_19} className="image image_PML_img" alt="" />
                                        <img src={shape_20} className="image image_w100" alt="" />
                                        <img src={shape_21} className="image image_w150" alt="" />
                                        <img src={shape_22} className="image image_w160" alt="" />
                                        <img src={shape_23} className="image image_w100" alt="" />
                                        <img src={shape_24} className="image image_w100" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 logo_divider_row d-sm-block d-md-none d-lg-none d-xl-none">
                                <div className="d-flex justify-content-around align-items-end">
                                    <img src={shape_19} className="image image_w100" alt="" />
                                    <img src={shape_20} className="image image_w100" alt="" />
                                    <img src={shape_21} className="image image_w150" alt="" />
                                </div>
                                <div className="d-flex justify-content-around align-items-end">
                                    <img src={shape_22} className="image image_w160" alt="" />
                                    <img src={shape_23} className="image image_w100" alt="" />
                                    <img src={shape_24} className="image image_w100" alt="" />
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AiPage);