import {
    Vector3, HemisphericLight,DirectionalLight, ArcRotateCamera, Color3, Color4, GlowLayer, Engine, Scene, FreeCamera
} from '@babylonjs/core';

import { ConfigDataJson } from './config-data';
import { MainSceneCreator } from './scene-creator';

export class WebGL {

    _canvas;
    _engine;
    _scene;

    constructor(canvasElement) {

        // Instantiate engine.
        this._canvas = document.getElementById(canvasElement);
        this._engine = new Engine(this._canvas, true);
    }

    createScene() {

        // Instantiate scene.
        this._scene = new Scene(this._engine);

        this._scene.clearColor = new Color4(0, 0, 0, 1.0);

        let starData = ConfigDataJson;
        let mainSceneCreator;
        let starLimit = 3000;
        let starScale = 0.5;
        let radius = 300;
        let showAsterisms = false;
        let asterismColor = new Color3(0, 0, 0.7);
        let twinkleStars = true;

        mainSceneCreator = new MainSceneCreator("MainSceneCreator", this._scene, starData, radius, starLimit, starScale, showAsterisms, asterismColor, twinkleStars);

        var camera = new FreeCamera("camera1", new Vector3(0, 5, -10), this._scene);
       // camera.setTarget(Vector3.Zero());
        // var camera = new ArcRotateCamera("camera", -Math.PI / 2, Math.PI / 2.5, 1.5, new Vector3(0, 0, 0), this._scene);

        const canvas = this._scene.getEngine().getRenderingCanvas();

        this._scene.clearColor = new Color3(0.03, 0.05, 0.09);

        camera.setTarget(Vector3.Zero());
        // This attaches the camera to the canvas
        camera.attachControl(canvas, true);

        // Prevents scroll zooming.
        camera.lowerRadiusLimit = camera.upperRadiusLimit = camera.radius;

        // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
        // var light = new HemisphericLight("light", new Vector3(-10, 0, 15), this._scene);
        // light.intensity = 1.5;

        var light = new DirectionalLight("DirectionalLight", new Vector3(10, -3, 0), this._scene);
        light.intensity = 1;
        light.specular = new Color3(0.7,0.7,0.7,0.5)

        // let gl = new GlowLayer("glow", this._scene);
        // gl.intensity = 1;

        return this._scene;
    }

    renderScene() {

        // Start render loop.
        this._engine.runRenderLoop(() => {
            this._scene.render();
        });

        // Add resize event listener.
        window.addEventListener('resize', () => {
            this._engine.resize();
        });
    }
}