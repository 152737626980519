import React from 'react';
import shape_19 from '../../assets/images/shape-19.png';
import shape_20 from '../../assets/images/shape-20.png';
import shape_21 from '../../assets/images/shape-21.png';
import shape_22 from '../../assets/images/shape-22.png';
import shape_23 from '../../assets/images/shape-23.png';
import shape_24 from '../../assets/images/shape-24.png';
import WhyTab from '../../assets/images/whyPage-whyTab.svg';
import HowTab from '../../assets/images/whyPage-howTab.svg';
import whatTab from '../../assets/images/whyPage-whatTab.svg';
import WhiteCircle from '../../assets/images/circle-white.svg';
import BlueCircle from '../../assets/images/circle-blue.svg';
import Dedicated from '../../assets/images/Dedicated.svg';
import Increased from '../../assets/images/Increased.svg';
import Supported from '../../assets/images/Supported.svg';
import Vetted from '../../assets/images/Vetted.svg';
import Puzzle from '../../assets/images/puzzle.svg';
import Fee from '../../assets/images/Fee.svg';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SubNavigation from '../../components/Sub-navigation';
import { Link } from 'react-router-dom';

import { getFromStorage, removeFromStorage } from '../../common/service';
import { CONSTANTS } from '../../constants';
import { API_ENDPOINTS, AUTH_HEADER_KEY, PLAIN_HEADERS } from '../../constants/api-endpoints';
import { apiCall } from '../../common/NetworkCalls';
import { withRouter } from 'react-router-dom';

class AboutPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserLoggedIn: false,
            userInfo: ''
        }
    }

    componentDidMount() {
        var userData = getFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        console.log(userData)
        if (userData) {
            userData = JSON.parse(userData);
            this.setState({ isUserLoggedIn: true, userInfo: userData });
        }
    }

    logOutUser() {
        this.setState({ isUserLoggedIn: false, userInfo: '' });
        removeFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        var headers = PLAIN_HEADERS;
        headers[AUTH_HEADER_KEY] = `Bearer ${this.state.userInfo.authToken}`;
        return apiCall(API_ENDPOINTS.LOGOUT, 'GET', '',).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {

        return (
            <div className="about-page" id="#why">
                <Header
                    isUserLoggedIn={this.state.isUserLoggedIn}
                    userInfo={this.state.userInfo}
                    logOutUser={() => {
                        this.logOutUser()
                    }}
                />
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active trusteddev-tab" id="trustedDev" role="tabpanel" aria-labelledby="trustedDev-tab">
                        <div className="why-page-banner">
                            <SubNavigation
                                isUserLoggedIn={this.state.isUserLoggedIn}
                                userInfo={this.state.userInfo}
                                logOutUser={() => {
                                    this.logOutUser()
                                }}
                            />
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-12 nav-pill-position">
                                <div className="row justify-content-center no-gutters">
                                    <nav className="nav nav-pills nav-justified">
                                        <Link className="nav-link nav-pill" to={'why'}>
                                            <img src={WhyTab} alt="why-tab" className="header-tab active-tab" />
                                            <label className="whypage-tabLabel color_why_active">Why?</label>
                                        </Link>
                                        <Link className="nav-link nav-pill" to={'how'}>
                                            <img src={HowTab} alt="how-tab" className="header-tab" />
                                            <label className="whypage-tabLabel color_white">How?</label>
                                        </Link>
                                        <Link className="nav-link nav-pill" to={'what'}>
                                            <img src={whatTab} alt="what-tab" className="header-tab" />
                                            <label className="whypage-tabLabel color_what_inactive">What?</label>
                                        </Link>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                <div className="card text-center card_border bg_transperant wow animate__slideInLeft animate__faster animate__animated" data-wow-delay=".2s">
                                    <div className="card-header card_border bg_sky_blue">
                                        <div className="offset-xl-3 col-xl-9 offset-lg-3 col-lg-9 offset-md-3 col-md-9 col-sm-12">
                                            <h2 className="why-trustedDev wow animate__fadeIn animate__animated" data-wow-delay=".6s">
                                                Why Trusted<span className="text-style"> Dev</span> ?
                                        </h2>
                                        </div>
                                    </div>
                                    <div className="card-body card_body_color">
                                        <div className="row no-gutters">
                                            <div className="offset-xl-3 col-xl-9 offset-lg-3 col-lg-9 offset-md-3 col-md-9 col-sm-12">
                                                <p className="why-content wow animate__fadeIn animate__animated" data-wow-delay=".7s">
                                                    <span className="text-style">Finding, vetting and integrating</span> top talent into your existing software development team creates challenges and risks for the enterprise. The <span className="text-style">Trusted Dev</span> Portal is <span className="text-style">designed with the challenges of the modern enterprise in mind.</span> <span className="text-style-1">Trust</span> our passionate team with over a decade of software development resource vetting experience to be your sherpa throughout the competitive software development talent acquisition life cycle.
                                                </p>
                                                <div className="row no-gutters Rectangle-wrapper">
                                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 wow animate__zoomIn animate__faster animate__animated" data-wow-delay=".2s">
                                                        <div className="rectangle rectangle-bg-sky-style">
                                                            <div className="position-relative">
                                                                <img src={WhiteCircle} className="rectangle-img" alt="" />
                                                                <img src={Vetted} className="icon-size icon-Vetted" alt="" />
                                                            </div>
                                                            <h4 className="rectangle-heading">Pre-vetted</h4>
                                                            <p className="rectangle-label">software development <span className="text-style">candidates</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 wow animate__zoomIn animate__faster animate__animated" data-wow-delay=".3s">
                                                        <div className="rectangle rectangle-bg-white-style">
                                                            <div className="position-relative">
                                                                <img src={WhiteCircle} className="rectangle-img" alt="" />
                                                                <img src={Fee} className="icon-size icon-Fee" alt="" />
                                                            </div>
                                                            <h4 className="rectangle-heading">$0.00</h4>
                                                            <p className="rectangle-label">Recruiting <span className="text-style">fee</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 wow animate__zoomIn animate__faster animate__animated" data-wow-delay=".4s">
                                                        <div className="rectangle rectangle-bg-sky-style">
                                                            <div className="position-relative">
                                                                <img src={WhiteCircle} className="rectangle-img" alt="" />
                                                                <img src={Supported} className="icon-size icon-Supported" alt="" />
                                                            </div>
                                                            <h4 className="rectangle-heading">Supported</h4>
                                                            <p className="rectangle-label">by a <span className="text-style">world class</span> framework</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 wow animate__zoomIn animate__faster animate__animated" data-wow-delay=".8s">
                                                        <div className="rectangle rectangle-bg-white-style">
                                                            <div className="position-relative">
                                                                <img src={BlueCircle} className="rectangle-img" alt="" />
                                                                <img src={Dedicated} className="icon-size icon-Dedicated" alt="" />
                                                            </div>
                                                            <h4 className="rectangle-heading">Dedicated</h4>
                                                            <p className="rectangle-label">customer success VIP <span className="text-style">concierge</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 wow animate__zoomIn animate__faster animate__animated" data-wow-delay=".9s">
                                                        <div className="rectangle rectangle-bg-sky-style">
                                                            <div className="position-relative">
                                                                <img src={WhiteCircle} className="rectangle-img" alt="" />
                                                                <img src={Puzzle} className="icon-size icon-Puzzle" alt="" />
                                                            </div>
                                                            <h4 className="rectangle-heading">They join</h4>
                                                            <p className="rectangle-label">your internal team</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 wow animate__zoomIn animate__faster animate__animated" data-wow-delay="1s">
                                                        <div className="rectangle rectangle-bg-white-style">
                                                            <div className="position-relative">
                                                                <img src={BlueCircle} className="rectangle-img" alt="" />
                                                                <img src={Increased} className="icon-size icon-Increased" alt="" />
                                                            </div>
                                                            <h4 className="rectangle-heading">Increased</h4>
                                                            <p className="rectangle-label">enterprise <span className="text-style">efficiences</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-page-why-footer-banner">
                            <h3 className="Proud-Member-of-the-Following-Organizations p-4 wow animate__fadeIn animate__animated" data-wow-delay=".2s">Proud <span className="color_color1">Member</span> of the Following Organizations:</h3>
                            <div className="row no-gutters justify-content-start wow animate__slideInLeft animate__faster animate__animated" data-wow-delay=".8s">
                                <div className="col-xl-10 col-lg-10 col-md-10 logo_divider_row d-none d-md-block d-lg-block d-xl-block">
                                    <div className="row justify-content-end">
                                        <div className="offset-xl-2 col-xl-push-8 offset-lg-3 col-lg-9  offset-md-3 col-md-9 d-flex justify-content-center align-items-center">
                                            <img src={shape_19} className="image image_PML_img wow animate__fadeIn animate__animated" data-wow-delay="1s" alt="" />
                                            <img src={shape_20} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.1s" alt="" />
                                            <img src={shape_21} className="image image_w150 wow animate__fadeIn animate__animated" data-wow-delay="1.2s" alt="" />
                                            <img src={shape_22} className="image image_w160 wow animate__fadeIn animate__animated" data-wow-delay="1.3s" alt="" />
                                            <img src={shape_23} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.4s" alt="" />
                                            <img src={shape_24} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.5s" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 logo_divider_row d-sm-block d-md-none d-lg-none d-xl-none">
                                    <div className="d-flex justify-content-around align-items-end">
                                        <img src={shape_19} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1s" alt="" />
                                        <img src={shape_20} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.1s" alt="" />
                                        <img src={shape_21} className="image image_w150 wow animate__fadeIn animate__animated" data-wow-delay="1.2s" alt="" />
                                    </div>
                                    <div className="d-flex justify-content-around align-items-end">
                                        <img src={shape_22} className="image image_w160 wow animate__fadeIn animate__animated" data-wow-delay="1.3s" alt="" />
                                        <img src={shape_23} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.4s" alt="" />
                                        <img src={shape_24} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.5s" alt="" />
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(AboutPage);
