import React from 'react';    
import binaryNumber from '../assets/images/1001-0101.svg';
import suitcase from '../assets/images/suitcase.svg';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SubNavigation from '../components/Sub-navigation';
import { Link, withRouter } from 'react-router-dom';

import { getFromStorage, removeFromStorage } from '../common/service';
import { CONSTANTS } from '../constants';
import { API_ENDPOINTS, AUTH_HEADER_KEY, PLAIN_HEADERS } from '../constants/api-endpoints';
import { apiCall } from '../common/NetworkCalls';

class EngagementPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserLoggedIn: false,
            userInfo: ''
        }
    }

    componentDidMount() {
        var userData = getFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        console.log(userData)
        if (userData) {
            userData = JSON.parse(userData);
            this.setState({ isUserLoggedIn: true, userInfo: userData });
        }
    }

    logOutUser() {
        this.setState({ isUserLoggedIn: false, userInfo: '' });
        removeFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        var headers = PLAIN_HEADERS;
        headers[AUTH_HEADER_KEY] = `Bearer ${this.state.userInfo.authToken}`;
        return apiCall(API_ENDPOINTS.LOGOUT, 'GET', '',).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {

        return (
            <div className="Engagement-page">
                <Header
                    isUserLoggedIn={this.state.isUserLoggedIn}
                    userInfo={this.state.userInfo}
                    logOutUser={() => {
                        this.logOutUser()
                    }}
                />
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active trusteddev-tab" id="trustedDev" role="tabpanel" aria-labelledby="trustedDev-tab">
                        <div className="engagement-page-banner">
                            <SubNavigation
                                isUserLoggedIn={this.state.isUserLoggedIn}
                                userInfo={this.state.userInfo}
                                logOutUser={() => {
                                    this.logOutUser()
                                }}
                            />  
                            <div className="blue-shadeBg">
                                <div className="col-md-12">
                                    <h2 className="main-heading wow animate__animated animate__fadeInDown animation_delay-0point2">Our Engagement Model</h2> 
                                    <div className="longdash"></div>
                                </div> 
                                <div className="engagement-card-bottom">
                                    <div className="row no-gutters justify-content-center">
                                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12">
                                            <div className="card engagement-card wow animate__fadeInUp animate__faster animate__animated animation_delay-0point5">
                                                <div className="card-header engagement-card-header"> 
                                                    <div className="card-circle wow animate__rotateIn animate__animated animation_delay-1">
                                                        <img src={ suitcase } alt="" className="suitcase-img" />
                                                    </div>
                                                    <h4 className="heading">CTO as a Service </h4> 
                                                </div>
                                                <div className="card-body p-0">
                                                    <div className="row no-gutters color-body"> 
                                                        <p className="engagement-paragraph engagement-padding engagement-paraHeight">
                                                            We pre-vet and retain full time interim CTO caliber enterprise professional services resources to solve your most complex enterprise technical and business challenges.
                                                        </p> 
                                                        <div className="light-blue-bg engagement-padding">
                                                            <h3 className="Engagement-Duration">Engagement Duration</h3>
                                                            <h5 className="Engagement-Time">Short Term Interim CTO and 3, 6 or 12 month contract to hire</h5>
                                                        </div>
                                                        <div className="d-block m-auto engagement-padding">
                                                            <h3 className="Engagement-Duration">Full Time or Part Time: </h3> 
                                                            <h5 className="engagement-paragraph">Full Time or Part Time</h5> 
                                                        </div>
                                                    </div>
                                                    <div className="engagement-footer engagement-padding">  
                                                        <button className="btn engagement-hire-btn wow animate__bounceIn animate__animated animation_delay-0point2"
                                                            onClick={() => {
                                                                this.props.history.push("/basic-info");
                                                            }}
                                                            disabled={this.state.isUserLoggedIn}
                                                        >
                                                            hire top talent
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12">
                                            <div className="card engagement-card wow animate__fadeInUp animate__faster animate__animated animation_delay-0point5">
                                                <div className="card-header engagement-card-header"> 
                                                    <div className="card-circle wow animate__rotateIn animate__animated animation_delay-1"> 
                                                        <img src={ binaryNumber } alt="" className="binary-img" />
                                                    </div>
                                                    <h4 className="heading">Technical Staff Augmentation {'&'} Professional Services</h4> 
                                                </div>
                                                <div className="card-body p-0">
                                                    <div className="row no-gutters color-body"> 
                                                        <p className="engagement-paragraph engagement-padding engagement-paraHeight">
                                                        Trusted Dev pre-vets and supports enterprise software engineering talent that joins your internal team on a contract to hire basis.
                                                        </p> 
                                                        <div className="light-blue-bg engagement-padding">
                                                            <h3 className="Engagement-Duration">Engagement Duration</h3>
                                                            <h5 className="Engagement-Time">3, 6 or 12 month contract to hire</h5>
                                                        </div>
                                                        <div className="d-block m-auto engagement-padding">
                                                            <h3 className="Engagement-Duration">Full Time or Part Time: </h3> 
                                                            <h5 className="engagement-paragraph">Full Time </h5> 
                                                        </div>
                                                    </div>
                                                    <div className="engagement-footer engagement-padding">  
                                                        <button className="btn engagement-hire-btn wow animate__bounceIn animate__animated animation_delay-0point2"
                                                            onClick={() => {
                                                                this.props.history.push("/basic-info");
                                                            }}
                                                            disabled={this.state.isUserLoggedIn}
                                                        >
                                                            hire top talent
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div> 
                                    </div> 
                                </div> 
                            </div>  
                            <Footer />
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}


export default withRouter(EngagementPage);