import React from 'react';
import siteLogo from '../../assets/images/site_logo_light.svg';
import identifyImg from '../../assets/images/identity.svg';
import CiircleBg from '../../assets/images/ellipse-1-copy-9.png';
import Culture from '../../assets/images/Culture.svg';
import OngoingAI from '../../assets/images/On-goingAI.svg';
import ProblemSolving from '../../assets/images/Problem-Solving.svg';
import TestProject from '../../assets/images/Test-Project.svg';
import shape12 from '../../assets/images/shape-12.png';
import Step1 from '../../assets/images/Step1.svg';
import MobileStep1 from '../../assets/images/MobileStep1.svg';
import MobileStep2 from '../../assets/images/MobileStep2.svg';
import Arrow1 from '../../assets/images/Arrow1.svg';
import Arrow2 from '../../assets/images/Arrow2.svg';
import Step2 from '../../assets/images/Step2.svg';
import Step3 from '../../assets/images/Step3.svg';
import Arrow3 from '../../assets/images/Arrow3.svg';
import Step4 from '../../assets/images/Step4.svg';
import Step5 from '../../assets/images/Step5.svg';
import Arrow4 from '../../assets/images/Arrow4.svg';
import Mobile_Arrow from '../../assets/images/ArrowForMobileVersion.svg';
import shape_19 from '../../assets/images/shape-19.png';
import shape_20 from '../../assets/images/shape-20.png';
import shape_21 from '../../assets/images/shape-21.png';
import shape_22 from '../../assets/images/shape-22.png';
import shape_23 from '../../assets/images/shape-23.png';
import shape_24 from '../../assets/images/shape-24.png';
import ReadyToHire from '../../assets/images/Ready-to-hire.svg';
import { withRouter } from 'react-router-dom';
import BgAnimation from './bg-animation';

//Circle
import circle1 from '../../assets/images/Circle/Animations_Circle1.svg';
import circle2 from '../../assets/images/Circle/Animations_Circle2.svg';
import circle3 from '../../assets/images/Circle/Animations_Circle3.svg';
import circle4 from '../../assets/images/Circle/Animations_Circle4.svg';
import circle5 from '../../assets/images/Circle/Animations_Circle5.svg';
import circle6 from '../../assets/images/Circle/Animations_Circle6.svg';
import circle7 from '../../assets/images/Circle/Animations_Circle7.svg';
import circle8 from '../../assets/images/Circle/Animations_Circle8.svg';
import circle9 from '../../assets/images/Circle/Animations_Circle9.svg';
import circle10 from '../../assets/images/Circle/Animations_Circle10.svg';
import circle11 from '../../assets/images/Circle/Animations_Circle11.svg';
import circle12 from '../../assets/images/Circle/Animations_Circle12.svg';
import circle13 from '../../assets/images/Circle/Animations_Circle13.svg';

//Clock
import clock1 from '../../assets/images/AnimationClock/Animations_Clock1.svg';
import clock2 from '../../assets/images/AnimationClock/Animations_Clock2.svg';
import clock3 from '../../assets/images/AnimationClock/Animations_Clock3.svg';
import clock4 from '../../assets/images/AnimationClock/Animations_Clock4.svg';
import clock5 from '../../assets/images/AnimationClock/Animations_Clock5.svg';
import clock6 from '../../assets/images/AnimationClock/Animations_Clock6.svg';

//Bar
import bar0 from '../../assets/images/Bar/Animations_Bar0.svg';
import bar1 from '../../assets/images/Bar/Animations_Bar1.svg';
import bar2 from '../../assets/images/Bar/Animations_Bar2.svg';
import bar3 from '../../assets/images/Bar/Animations_Bar3.svg';
import bar4 from '../../assets/images/Bar/Animations_Bar4.svg';
import bar5 from '../../assets/images/Bar/Animations_Bar5.svg';
import bar6 from '../../assets/images/Bar/Animations_Bar6.svg';
import bar7 from '../../assets/images/Bar/Animations_Bar7.svg';
import bar8 from '../../assets/images/Bar/Animations_Bar8.svg';
import bar9 from '../../assets/images/Bar/Animations_Bar9.svg';
import bar10 from '../../assets/images/Bar/Animations_Bar10.svg';
import bar11 from '../../assets/images/Bar/Animations_Bar11.svg';
import bar12 from '../../assets/images/Bar/Animations_Bar12.svg';
import bar13 from '../../assets/images/Bar/Animations_Bar13.svg';
import bar14 from '../../assets/images/Bar/Animations_Bar14.svg';
import bar15 from '../../assets/images/Bar/Animations_Bar15.svg';

//Cog
import cog1 from '../../assets/images/cog/Animations_Cog1.svg';
import cog2 from '../../assets/images/cog/Animations_Cog2.svg';
import cog3 from '../../assets/images/cog/Animations_Cog3.svg';
import cog4 from '../../assets/images/cog/Animations_Cog4.svg';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SubNavigation from '../../components/Sub-navigation';

import { getFromStorage, removeFromStorage } from '../../common/service';
import { CONSTANTS } from '../../constants';
import { API_ENDPOINTS, AUTH_HEADER_KEY, PLAIN_HEADERS } from '../../constants/api-endpoints';
import { apiCall } from '../../common/NetworkCalls';

class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserLoggedIn: false,
            userInfo: ''
        }
    }

    componentDidMount() {
        var userData = getFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        console.log(userData)
        if (userData) {
            userData = JSON.parse(userData);
            this.setState({ isUserLoggedIn: true, userInfo: userData });
        }
    }

    logOutUser() {
        this.setState({ isUserLoggedIn: false, userInfo: '' });
        removeFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        var headers = PLAIN_HEADERS;
        headers[AUTH_HEADER_KEY] = `Bearer ${this.state.userInfo.authToken}`;
        return apiCall(API_ENDPOINTS.LOGOUT, 'GET', '',).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {

        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }

        return (
            <div className="Home-Page">
                <Header
                    isUserLoggedIn={this.state.isUserLoggedIn}
                    userInfo={this.state.userInfo}
                    logOutUser={() => {
                        this.logOutUser()
                    }}
                />
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active trusteddev-tab" id="trustedDev" role="tabpanel" aria-labelledby="trustedDev-tab">
                        <div className="home-page-background">
                            <div className="home-page-banner">
                                <SubNavigation
                                    isUserLoggedIn={this.state.isUserLoggedIn}
                                    userInfo={this.state.userInfo}
                                    logOutUser={() => {
                                        this.logOutUser()
                                    }}
                                />
                                 <BgAnimation/>
                                <div className="row no-gutters">
                                    <div className="col-md-12">
                                        <div className="text-center top-space">
                                            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                                                <img src={siteLogo} alt="" className="mobile-banner-logo margintop50 wow animate__animated animate__zoomIn" /><br />
                                            </div>
                                            <div className="longdash longdash_blue longdash-mobi d-md-none d-lg-none d-xl-none"></div>
                                            <h1 className="font40 banner_heading heading_font wow animate__animated animate__fadeInDown">
                                                AI, humans and software engineering <br />
                                                staff augmentation never looked<br />
                                                so good together.
                                            </h1>
                                            <div className=" d-none d-md-block d-lg-block d-xl-block">
                                                <div className="longdash longdash_blue wow animate__animated animate__zoomIn"></div>
                                            </div>
                                            <p className="banner_paragraph wow animate__animated animate__zoomIn">
                                                We utilize AI and passionate humans to match vetted software engineers and CTO's to enterprise companies based on technical acumen, creative problem solving and culture fit to solve their biggest challenges.
                                            </p>
                                            <button className="nav-link mobile_hire_button blue-color d-md-none d-lg-none d-xl-none btn font18 font-weight700 btn-sm capital_letter wow animate__animated animate__zoomIn"
                                                onClick={() => {
                                                    this.props.history.push("/basic-info");
                                                }}
                                                disabled={this.state.isUserLoggedIn}
                                            >
                                                Hire Top Talent
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters justify-content-center">
                                    <div className="col-xl-6 col-lg-7 col-md-9 col-sm-12 col-xs-12">
                                        <div className="card Vetting-Methodology-card text-center card_border wow animate__fadeInUp animate__faster animate__animated">
                                            <div className="card-header Vetting-Methodology-card-header card_border bg_sky_blue">
                                                <h2 className="text-light The-TrustedDev-Vetting-Methodology">
                                                    <span>The TrustedDev </span>
                                                    <span className="The-TrustedDev-Vetting-Methodology-text-style-1">Vetting </span>
                                                    <span className="The-TrustedDev-Vetting-Methodology-text-style-2">Methodology</span>
                                                </h2>
                                            </div>
                                            <div className="card-body card_body_color overflowX">
                                                <div className="row no-gutters">
                                                    <div className="col-12">
                                                        <div className="row no-gutters">
                                                            <div className="col-6">
                                                                <div className=" ">
                                                                    <div className="padding0">
                                                                        <div className="identifyImg wow animate__animated animate__bounceIn">
                                                                            <img src={CiircleBg} alt="bg" className="icon-circleBgLtr" />
                                                                            <img src={identifyImg} className="icon icon-identity" alt="" />
                                                                        </div>
                                                                        <span className="card_lineLeft_1 card_line wow animate__animated animate__faster animate__fadeInRightBig">
                                                                            <span className="digit digit_01">01</span>
                                                                            <span className="blue_dot blue_dot_step1"></span>
                                                                        </span>
                                                                        <div className="identifyContent wow animate__animated animate__fadeIn">
                                                                            <h3 className="Vetting-Methodology-step-heading color_color6">Identify</h3>
                                                                            <p className="font16 The-TrustedDev-Vetting-Methodology-Card-Content color_why_active">
                                                                                We identify then vet top software engineering talent from a technical, creative problem solving, communication and tailored culture fit perspective.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className=" ">
                                                                    <div className="cultureImg wow animate__animated animate__bounceIn">
                                                                        <img src={CiircleBg} alt="bg" className="icon-circleBgLtr" />
                                                                        <img src={Culture} className="icon icon-Culture" alt="" />
                                                                    </div>
                                                                    <span className="card_lineLeft_3 card_line wow animate__animated animate__faster animate__fadeInRightBig">
                                                                        <span className="digit digit_03">03</span>
                                                                        <span className="blue_dot blue_dot_step3"></span>
                                                                    </span>
                                                                    <div className="cultureContent wow animate__animated animate__fadeIn">
                                                                        <h3 className="Vetting-Methodology-step-heading color_color6">Culture fit test</h3>
                                                                        <p className="font16 The-TrustedDev-Vetting-Methodology-Card-Content color_why_active">
                                                                            Our team meets with the candidate to ensure alignment of their personality with your company culture.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className=" ">
                                                                    <div className="ongoingAiImg wow animate__animated animate__bounceIn">
                                                                        <img src={CiircleBg} alt="bg" className="icon-circleBgLtr" />
                                                                        <img src={OngoingAI} className="icon icon-OngoingAI" alt="" />
                                                                    </div>
                                                                    <span className="card_lineLeft_5 card_line wow animate__animated animate__faster animate__fadeInRightBig">
                                                                        <span className="digit digit_05">05</span>
                                                                        <span className="blue_dot blue_dot_step5"></span>
                                                                    </span>
                                                                    <div className="OngoingAiContent wow animate__animated animate__fadeIn">
                                                                        <h3 className="Vetting-Methodology-step-heading color_color6">On-Going AI and human optimization</h3>
                                                                        <p className="font16 The-TrustedDev-Vetting-Methodology-Card-Content color_why_active">
                                                                            The Trusted Dev Portal and internal team optimizes your software development recruitment life cycle with AI and machine learning algorithms.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="position-relative">
                                                                    <div className="problemSolvingImg wow animate__bounceIn animate__animated">
                                                                        <img src={CiircleBg} alt="bg" className="icon-circleBgRtl" />
                                                                        <img src={ProblemSolving} className="icon icon-ProblemSolving" alt="" />
                                                                    </div>
                                                                    <span className="card_lineRight_2 card_line wow animate__animated animate__faster animate__fadeInLeftBig">
                                                                        <span className="digit digit_02">02</span>
                                                                        <span className="blue_dot blue_dot_step2"></span>
                                                                    </span>
                                                                    <div className="problemSolvingContent wow animate__animated animate__fadeIn">
                                                                        <h3 className="Vetting-Methodology-step-heading color_color6">Problem solving and<br />technical assessment</h3>
                                                                        <p className="font16 The-TrustedDev-Vetting-Methodology-Card-Content color_why_active">
                                                                            A quantifiable software development assessment is administered to invite only talent to ensure technical and creative problem solving proficiency based on your preferred tech stack.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="position-relative">
                                                                    <div className="testProjectImg wow animate__bounceIn animate__animated">
                                                                        <img src={CiircleBg} alt="bg" className="icon-circleBgRtl icon-MobilecircleBgRtl" />
                                                                        <img src={TestProject} className="icon icon-TestProject" alt="" />
                                                                    </div>
                                                                    <span className="card_lineRight_4 card_line wow animate__animated animate__faster animate__fadeInLeftBig">
                                                                        <span className="digit digit_04">04</span>
                                                                        <span className="blue_dot blue_dot_step4"></span>
                                                                    </span>
                                                                    <div className="testProjectContent wow animate__animated animate__fadeIn">
                                                                        <h3 className="Vetting-Methodology-step-heading color_color6">test project</h3>
                                                                        <p className="font16 The-TrustedDev-Vetting-Methodology-Card-Content color_why_active">
                                                                            A test project is completed by the candidate and scored by our team to simulate a live project.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="dotted_line wow animate__slideInUp animate__faster">
                                                                    <span className="circle_blue_large circle_blue wow animate__animated animate__fadeIn">
                                                                        <span className="circle_blue_medium circle_blue">
                                                                            <span className="blue_dot last_dot">
                                                                            </span>
                                                                        </span>
                                                                        <div className="your-staff-is-ready-to-hire-wrapper wow animate__animated animate__fadeIn animate__faster">
                                                                            <img src={ReadyToHire} alt="" className="three-person-image" />
                                                                            <h3 className="ready-to-hire-label">your staff is <br /> ready to hire</h3>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters justify-content-start bg-color Implementation-of-AI overflowX">
                                    <div className="col-xl-10 col-lg-10 col-md-11 page_divider hide_under_575 d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
                                        <div className="row">
                                            <div className="offset-xl-2 offset-lg-2 offset-md-1 col-xl-10 col-lg-10 col-md-11">
                                                <h3 className="text-center The-Implementation-of-AI-With-Software-Development-Resource-Vet">
                                                    The Implementation of AI With Software Development Resource Vetting <span className="The-Implementation-of-AI-With-Software-Development-Resource-Vet_text-style-1">Produces More <span className="color_color1"> Predictable Results</span></span> For the Enterprise
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 page_divider d-xs-block d-sm-block d-md-none d-lg-none d-xl-none">
                                        <div className="row no-gutters">
                                            <h3 className="text-center The-Implementation-of-AI-With-Software-Development-Resource-Vet">The implementation of AI with Software Development Resource Vetting <span className="The-Implementation-of-AI-With-Software-Development-Resource-Vet_text-style-1">Produces More <span className="color_color1"> Predictable Results</span></span> For the Enterprise</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="page_divider_banner overflowX">
                                    <div className="row no-gutters justify-content-center">
                                        <div className="col-xl-8 col-lg-8 col-md-10 mt-4 col-sm-12 col-xs-12">
                                            <div className="row no-gutters">
                                                <div className="col-6 right_cut_corners bg_white">
                                                    <div className="row no-gutters bg_white padding24 px-3 Rectangle-box">
                                                        <div className="col-md-3 d-flex align-items-center justify-content-center">
                                                            <div className="position-relative">
                                                                <div class="Animations_Circle">
                                                                    <img src={circle1} className="position-absolute" alt="" id="frame1" />
                                                                    <img src={circle2} className="position-absolute" alt="" id="frame2" />
                                                                    <img src={circle3} className="position-absolute" alt="" id="frame3" />
                                                                    <img src={circle4} className="position-absolute" alt="" id="frame4" />
                                                                    <img src={circle5} className="position-absolute" alt="" id="frame5" />
                                                                    <img src={circle6} className="position-absolute" alt="" id="frame6" />
                                                                    <img src={circle7} className="position-absolute" alt="" id="frame7" />
                                                                    <img src={circle8} className="position-absolute" alt="" id="frame8" />
                                                                    <img src={circle9} className="position-absolute" alt="" id="frame9" />
                                                                    <img src={circle10} className="position-absolute" alt="" id="frame10" />
                                                                    <img src={circle11} className="position-absolute" alt="" id="frame11" />
                                                                    <img src={circle12} className="position-absolute" alt="" id="frame12" />
                                                                    <img src={circle13} className="position-absolute" alt="" id="frame13" />
                                                                </div>
                                                            </div>
                                                            <h3 className="d-md-none d-lg-none d-xl-none text-left ml-3 digit-96">96%</h3>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <h3 className="d-none d-md-block d-lg-block d-xl-block text-left color_color2 digit-96">96%</h3>
                                                            <p className="Belive-AI-content center_content">
                                                                Believe AI can greatly enhance <br />talent acquisition and retention. <small>[1]</small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 left_cut_corners bg_color2">
                                                    <div className="row no-gutters bg_color2 padding24 px-3 Rectangle-box">
                                                        <div className="col-md-9 text-right">
                                                            <div className="center_icon_heading">
                                                                <h3 className="color_color2 hour24-content mr-3">14 hours</h3>
                                                                <div className="position-relative d-sm-block d-md-none d-lg-none d-xl-none ">
                                                                    <div className="Animations_Clock">
                                                                        <img src={clock1} alt="" class="Animations_Clock" id="frame1" />
                                                                        <img src={clock2} alt="" class="Animations_Clock" id="frame2" />
                                                                        <img src={clock3} alt="" class="Animations_Clock" id="frame3" />
                                                                        <img src={clock4} alt="" class="Animations_Clock" id="frame4" />
                                                                        <img src={clock5} alt="" class="Animations_Clock" id="frame5" />
                                                                        <img src={clock6} alt="" class="Animations_Clock" id="frame6" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className="Average-amount-content text-align">
                                                                Average amount of time per week <br /><span className="text-style">lost to manually</span> completing tasks.<small> [1]</small>
                                                            </p>
                                                        </div>
                                                        <div className="col-md-3 d-flex align-items-center justify-content-center">
                                                            <div className="position-relative d-none d-md-block d-lg-block d-xl-block">
                                                                <div class="Animations_Clock" alt="">
                                                                    <img src={clock1} alt="" class="position-absolute" id="frame1" />
                                                                    <img src={clock2} alt="" class="position-absolute" id="frame2" />
                                                                    <img src={clock3} alt="" class="position-absolute" id="frame3" />
                                                                    <img src={clock4} alt="" class="position-absolute" id="frame4" />
                                                                    <img src={clock5} alt="" class="position-absolute" id="frame5" />
                                                                    <img src={clock6} alt="" class="position-absolute" id="frame6" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center ">
                                                <div className="white-box"></div>
                                                <div className="blue-box"></div>
                                                <img src={shape12} className="four_box_center_img" alt="" />
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-6 bg_color2">
                                                    <div className="row no-gutters bg_color2 padding24 px-3 Rectangle-box">
                                                        <div className="col-md-3 d-flex align-items-center justify-content-center">
                                                            <div className="position-relative">
                                                                <div className="Animations_Bar">
                                                                    <img src={bar0} alt="" className="position-absolute" id="frame0" />
                                                                    <img src={bar1} alt="" className="position-absolute" id="frame1" />
                                                                    <img src={bar2} alt="" className="position-absolute" id="frame2" />
                                                                    <img src={bar3} alt="" className="position-absolute" id="frame3" />
                                                                    <img src={bar4} alt="" className="position-absolute" id="frame4" />
                                                                    <img src={bar5} alt="" className="position-absolute" id="frame5" />
                                                                    <img src={bar6} alt="" className="position-absolute" id="frame6" />
                                                                    <img src={bar7} alt="" className="position-absolute" id="frame7" />
                                                                    <img src={bar8} alt="" className="position-absolute" id="frame8" />
                                                                    <img src={bar9} alt="" className="position-absolute" id="frame9" />
                                                                    <img src={bar10} alt="" className="position-absolute" id="frame10" />
                                                                    <img src={bar11} alt="" className="position-absolute" id="frame11" />
                                                                    <img src={bar12} alt="" className="position-absolute" id="frame12" />
                                                                    <img src={bar13} alt="" className="position-absolute" id="frame13" />
                                                                    <img src={bar14} alt="" className="position-absolute" id="frame14" />
                                                                    <img src={bar15} alt="" className="position-absolute" id="frame15" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9 center_content">
                                                            <h3 className="text-left cost-of-not-automating-content"><span className="color_color1">Costs </span>of Not Automating</h3>
                                                            <ul className="list_type_none padding0">
                                                                <li className="list-item-content"><span className="text-style">41%</span> Lower Productivity</li>
                                                                <li className="list-item-content"><span className="text-style">35%</span> Higher Costs</li>
                                                                <li className="list-item-content"><span className="text-style">17%</span> Poor Candidate Experience <small>[1]</small></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 bg_white">
                                                    <div className="row no-gutters bg_white padding24 px-3 Rectangle-box">
                                                        <div className="col-md-9 col-sm-9  col-xs-9 text-right">
                                                            <h3 className="what-can-be-automated-content">What Can Be Automated?</h3>
                                                            <ul className="list_type_none padding0 d-none d-md-block d-lg-block d-xl-block">
                                                                <li className="percentage-line-content"><span className="text-style color_color1">100%</span> Sourcing, Screening {'&'} Matching</li>
                                                                <li className="percentage-line-content"><span className="text-style color_color7">20%</span> Candidate {'&'} Hiring Relation <small>[1]</small></li>
                                                            </ul>
                                                        </div>
                                                        <div className="d-none d-md-block d-lg-block d-xl-block">
                                                            <div className="col-md-3 col-sm-3  col-xs-3 d-flex align-items-center justify-content-center">
                                                                <div className="position-relative">
                                                                    <div className="Animations_Cog">
                                                                        <img src={cog1} alt="" className="position-absolute" id="frame1" />
                                                                        <img src={cog2} alt="" className="position-absolute" id="frame2" />
                                                                        <img src={cog3} alt="" className="position-absolute" id="frame3" />
                                                                        <img src={cog4} alt="" className="position-absolute" id="frame4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                                                            <div className="d-flex">
                                                                <ul className="list_type_none padding0">
                                                                    <li className="percentage-line-content"><span className="text-style color_color1">100%</span> Sourcing, Screening {'&'} Matching</li>
                                                                    <li className="percentage-line-content"><span className="text-style color_color7">20%</span> Candidate {'&'} Hiring Relation <small>[1]</small></li>
                                                                </ul>
                                                                <div className="position-relative">
                                                                    <div className="Animations_Cog">
                                                                        <img src={cog1} alt="" className="position-absolute" id="frame1" />
                                                                        <img src={cog2} alt="" className="position-absolute" id="frame2" />
                                                                        <img src={cog3} alt="" className="position-absolute" id="frame3" />
                                                                        <img src={cog4} alt="" className="position-absolute" id="frame4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="Revealing-Stats-label d-none d-md-block d-lg-block d-xl-block">
                                                [1] 12 Revealing Stats On How Recruiters Feel About AI, Ji-A Min <a href="https://ideal.com/how-recruiters-feel-about-ai/" target="_blank" rel="noreferrer" >https://ideal.com/how-recruiters-feel-about-ai/</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="overflowX">
                                    <div className="d-none d-xl-block d-lg-block d-md-block">
                                        <div className="row no-gutters justify-content-end Dedicated-Customer">
                                            <div className="col-xl-10 col-lg-10 col-md-10">
                                                <div className="blue_bar_page_divider wow animate__animated animate__fadeInRight animate__faster" data-wow-delay=".5s"> 
                                                    <div className="col-xl-9 col-lg-12 col-md-12">
                                                        <h3 className="A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring text-align wow animate__animated animate__fadeIn" data-wow-delay=".8s">A Dedicated Customer Success <span className="color_color1 A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring_text-style-1">Concierge</span> Works With <br /> Every Hiring Manager to Ensure<span className="A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring_text-style-1"> Campaign<span className="color_color1 A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring_text-style-1"> Success</span></span></h3>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-xl-none d-lg-none d-md-none d-sm-block">
                                        <div className="row no-gutters justify-content-end">
                                            <div className="col-12">
                                                <div className="blue_bar_page_divider wow animate__animated animate__fadeInRight animate__faster" data-wow-delay=".5s"> 
                                                    <div className="col-sm-12">
                                                        <h3 className="A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring text-align wow animate__animated animate__fadeIn" data-wow-delay=".8s">A Dedicated Customer Success <span className="color_color1 A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring_text-style-1">Concierge</span> Works With  Every Hiring Manager to Ensure<span className="A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring_text-style-1"> Marketplace<span className="color_color1 A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring_text-style-1"> Success</span></span></h3>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-section">
                                    <div className="row no-gutters">
                                        <div className="col-md-12 step-section">
                                            {/* step 1 */}
                                            <img src={Step1} alt="" className="d-none d-lg-block d-xl-block step-img step-img-step1 wow animate__zoomIn animate__animated" data-wow-delay=".1s" />
                                            <img src={MobileStep1} alt="" className="d-lg-none d-xl-none step-img step-img-step1 wow animate__zoomIn animate__animated" data-wow-delay=".1s" />
                                            <span className="step-label step-label-step1 wow animate__fadeIn animate__animated" data-wow-delay=".2s">STEP</span>
                                            <p className="d-none d-md-block d-lg-block d-xl-block step-content step-content-step1 wow animate__fadeIn animate__animated" data-wow-delay=".3s">
                                                You register a Trusted Dev Portal account to hire top and pre-vetted software engineering talent.
                                            </p>
                                            <p className="d-sm-block d-md-none d-lg-none d-xl-none step-content step-content-step1 wow animate__fadeIn animate__animated" data-wow-delay=".3s">
                                                You register an account to hire top and pre-vetted software engineering talent.
                                            </p>
                                            <img src={Arrow1} alt="" className="step-arrow step-arrow-step1 wow animate__fadeInTopLeft animate__animated" data-wow-delay=".4s" />
                                            <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step1 wow animate__fadeInRight animate__animated" />
                                            {/* step 2 */}
                                            <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step2 wow animate__fadeInLeft animate__animated" />
                                            <img src={Arrow2} alt="" className="step-arrow step-arrow-step2 wow animate__fadeInTopRight animate__animated" data-wow-delay=".5s" />
                                            <span className="step-label step-label-step2 wow animate__fadeIn animate__animated" data-wow-delay=".6s">STEP</span>
                                            <p className="step-content step-content-step2 wow animate__fadeIn animate__animated" data-wow-delay=".7s">
                                                A dedicated customer success concierge is assigned providing VIP service for your software engineering hiring requirements.
                                            </p>
                                            <img src={Step2} className="d-none d-lg-block d-xl-block step-img step-img-step2 wow animate__zoomIn animate__animated" alt="" data-wow-delay=".8s" />
                                            <img src={MobileStep2} className="d-lg-none d-xl-none step-img step-img-step2 wow animate__zoomIn animate__animated" alt="" data-wow-delay=".8s" />
                                            {/* step 3 */}
                                            <p className="step-content step-content-step3 wow animate__fadeIn animate__animated" data-wow-delay=".9s">
                                            Working in conjunction with your customer success concierge we vet top software engineering talent based on your hiring profile, culture fit and desired tech stack.
                                            </p>
                                            <span className="step-label step-label-step3 wow animate__fadeIn animate__animated" data-wow-delay="1s">STEP</span>
                                            <img src={Step3} className="step-img step-img-step3 wow animate__zoomIn animate__animated" alt="" data-wow-delay="1.1s" />
                                            <img src={Arrow3} className="step-arrow step-arrow-step3 wow animate__fadeInTopLeft animate__animated" alt="" data-wow-delay="1.2s" />
                                            <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step3 wow animate__fadeInLeft animate__animated" />
                                            {/* step 4 */}
                                            <span className="step-label step-label-step4 wow animate__fadeIn animate__animated" data-wow-delay="1.3s">STEP</span>
                                            <img src={Step4} className="step-img step-img-step4 wow animate__zoomIn animate__animated" alt="" data-wow-delay="1.4s" />
                                            <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step4 wow animate__fadeInLeft animate__animated" />
                                            <p className="step-content step-content-step4 wow animate__fadeIn animate__animated" data-wow-delay="1.5s">
                                                We curate pre-vetted talent and you decide who joins your internal team.
                                            </p>
                                            <img src={Arrow4} className="step-arrow step-arrow-step4 wow animate__fadeInTopRight animate__animated" alt="" data-wow-delay="1.6s" />
                                            {/* step 5 */}
                                            <img src={Step5} className="step-img step-img-step5 wow animate__zoomIn animate__animated" alt="" data-wow-delay="1.7s" />
                                            <span className="step-label step-label-step5 wow animate__fadeIn animate__animated" data-wow-delay="1.8s">STEP</span>
                                            <p className="step-content step-content-step5 wow animate__fadeIn animate__animated" data-wow-delay="1.9s">
                                                We optimize your software development recruitment life cycle with AI, machine learning and the human touch.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="page_bottom_divider_banner wow animate__fadeInUp animate__animated overflowX" data-wow-delay=".5s">
                                    <h3 className="Proud-Member-of-the-Following-Organizations p-4 wow animate__fadeIn animate__animated" data-wow-delay=".6s">Proud <span className="color_color1">Member</span> of the Following Organizations:</h3>
                                    <div className="row no-gutters justify-content-start wow animate__slideInLeft animate__faster animate__animated" data-wow-delay=".8s">
                                        <div className="col-xl-10 col-lg-10 col-md-10 logo_divider_row d-none d-md-block d-lg-block d-xl-block">
                                            <div className="row justify-content-end">
                                                <div className="offset-xl-2 col-xl-push-8 offset-lg-3 col-lg-9  offset-md-3 col-md-9 d-flex justify-content-center align-items-center">
                                                    <img src={shape_19} className="image image_PML_img wow animate__fadeIn animate__animated" data-wow-delay="1s" alt="" />
                                                    <img src={shape_20} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.1s" alt="" />
                                                    <img src={shape_21} className="image image_w150 wow animate__fadeIn animate__animated" data-wow-delay="1.2s" alt="" />
                                                    <img src={shape_22} className="image image_w160 wow animate__fadeIn animate__animated" data-wow-delay="1.3s" alt="" />
                                                    <img src={shape_23} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.4s" alt="" />
                                                    <img src={shape_24} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.5s" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 logo_divider_row d-sm-block d-md-none d-lg-none d-xl-none">
                                            <div className="d-flex justify-content-around align-items-end">
                                                <img src={shape_19} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1s" alt="" />
                                                <img src={shape_20} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.1s" alt="" />
                                                <img src={shape_21} className="image image_w150 wow animate__fadeIn animate__animated" data-wow-delay="1.2s" alt="" />
                                            </div>
                                            <div className="d-flex justify-content-around align-items-end">
                                                <img src={shape_22} className="image image_w160 wow animate__fadeIn animate__animated" data-wow-delay="1.3s" alt="" />
                                                <img src={shape_23} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.4s" alt="" />
                                                <img src={shape_24} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.5s" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(HomePage);