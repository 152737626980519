import CryptoJS from 'crypto-js';
import { ENCRYPTION_KEY, PLAIN_HEADERS } from '../../constants/api-endpoints';

export function apiCall(url, method, body = '', headers = '') {
    var fetchInfo = {
        method
    }

    if (body) {
        var reqData = {
            data: CryptoJS.AES.encrypt(JSON.stringify(body), ENCRYPTION_KEY).toString()
        }
        fetchInfo.body = JSON.stringify(reqData);
    }

    if (headers) {
        fetchInfo.headers = headers;
    } else {
        fetchInfo.headers = PLAIN_HEADERS;
    }

    console.log(fetchInfo)

    return fetch(url, { ...fetchInfo })
        .then(response => {
            if (response.ok) {
                return response.json().then((responseData) => {
                    var resData = responseData.res;
                    resData = CryptoJS.AES.decrypt(resData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
                    resData = JSON.parse(resData);
                    return resData;
                });
            } else {
                throw response.json();
            }
        })
        .catch((error) => {
            throw new Error(JSON.stringify(error));
        })
}