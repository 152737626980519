import React from 'react';
import ComputerImg from '../assets/images/computer-img.png';
import shape_19 from '../assets/images/shape-19.png';
import shape_20 from '../assets/images/shape-20.png';
import shape_21 from '../assets/images/shape-21.png';
import shape_22 from '../assets/images/shape-22.png';
import shape_23 from '../assets/images/shape-23.png';
import shape_24 from '../assets/images/shape-24.png';
import owasp from '../assets/images/owasp-img.png';
import Owsap from '../assets/images/Owsap.svg';
import Header from '../components/Header';
import SubNavigation from '../components/Sub-navigation';
import Footer from '../components/Footer';

import { getFromStorage, removeFromStorage } from '../common/service';
import { CONSTANTS } from '../constants';
import { API_ENDPOINTS, AUTH_HEADER_KEY, PLAIN_HEADERS } from '../constants/api-endpoints';
import { apiCall } from '../common/NetworkCalls';
import { withRouter } from 'react-router-dom';


//Table
import table1 from '../assets/images/Table/Tabl_1.svg';
import table2 from '../assets/images/Table/Tabl_2.svg';
import table3 from '../assets/images/Table/Tabl_3.svg';
import table4 from '../assets/images/Table/Tabl_4.svg';
import table5 from '../assets/images/Table/Tabl_5.svg';
import table6 from '../assets/images/Table/Tabl_6.svg';
import table7 from '../assets/images/Table/Tabl_7.svg';
import table8 from '../assets/images/Table/Tabl_8.svg';
import table9 from '../assets/images/Table/Tabl_9.svg';
import table10 from '../assets/images/Table/Tabl_10.svg';
import table11 from '../assets/images/Table/Tabl_11.svg';
import table12 from '../assets/images/Table/Tabl_12.svg';
import table13 from '../assets/images/Table/Tabl_13.svg';
import table14 from '../assets/images/Table/Tabl_14.svg';
import table15 from '../assets/images/Table/Tabl_15.svg';
import table16 from '../assets/images/Table/Tabl_16.svg';
import table17 from '../assets/images/Table/Tabl_17.svg';
import table18 from '../assets/images/Table/Tabl_18.svg';
import table19 from '../assets/images/Table/Tabl_19.svg';
import table20 from '../assets/images/Table/Tabl_20.svg';

class CustomPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserLoggedIn: false,
            userInfo: ''
        }
    }

    componentDidMount() {
        var userData = getFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        console.log(userData)
        if (userData) {
            userData = JSON.parse(userData);
            this.setState({ isUserLoggedIn: true, userInfo: userData });
        }
    }

    logOutUser() {
        this.setState({ isUserLoggedIn: false, userInfo: '' });
        removeFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        var headers = PLAIN_HEADERS;
        headers[AUTH_HEADER_KEY] = `Bearer ${this.state.userInfo.authToken}`;
        return apiCall(API_ENDPOINTS.LOGOUT, 'GET', '',).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {

        return (
            <div className="what-page AI-page Custom-SAAS-page">
                <Header
                    isUserLoggedIn={this.state.isUserLoggedIn}
                    userInfo={this.state.userInfo}
                    logOutUser={() => {
                        this.logOutUser()
                    }}
                />
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active trusteddev-tab" id="trustedDev" role="tabpanel" aria-labelledby="trustedDev-tab">
                        <div className="Custom-Saas-page-banner">
                            <SubNavigation
                                isUserLoggedIn={this.state.isUserLoggedIn}
                                userInfo={this.state.userInfo}
                                logOutUser={() => {
                                    this.logOutUser()
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                        <div className="card text-center card_border">
                            <div className="card-header first-card-header card_border bg_sky_blue wow animate__fadeInLeft animate__faster animate__animated overflowX" data-wow-delay=".2s">
                                <div className="offset-xl-2 col-xl-10 offset-lg-2 col-lg-10 offset-md-2 col-md-10 col-sm-12">
                                    <h2 className="SAAS-trustedDev wow animate__fadeIn animate__animated" data-wow-delay=".3s">
                                        Custom Enterprise Software Development
                                    </h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row no-gutters">
                                    <div className="offset-xl-3 col-xl-8 offset-lg-3 col-lg-8 offset-md-3 col-md-8 col-sm-12">
                                        <div className="row no-gutters">
                                            <div className="col-md-12">
                                                <p className="SAAS-paragraph wow animate__fadeIn animate__animated" data-wow-delay=".4s">
                                                Leverage our years of mission critical SaaS and PaaS software development experience to bring your modern software product concept to fruition. <span className="text-style">Trust our team to deliver a secure, </span>
                                                futuristic and visually impressive software <span className="text-style">product from your raw software product concept.</span>
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-md-12" style={ { zIndex:"1" } }>
                        <div className="SAAS-computer-img wow animate__fadeInUp animate__animated" data-wow-delay=".2s">
                            <img src={ComputerImg} alt="ComputerImage" className="computer-img" />
                        </div>
                    </div>
                </div>
                <div className="middle-background-banner">
                    <div className="black-shade">
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="Full-stack-development-img d-flex flex-column justify-content-between">
                                    <div className="row no-gutters justify-content-end">
                                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-11 col-11 overflowX">
                                            <div className="card bg_transperant">
                                                {/* <div className="card-header second-card-header wow animate__fadeInRight animate__faster animate__animated" data-wow-delay=".2s">
                                                    <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 p-0">
                                                        <h2 className="SAAS-trustedDev wow animate__fadeIn animate__faster animate__animated" data-wow-delay=".4s">
                                                            Custom Enterprise <span className="text-style">Cyber Security Software</span> Development
                                                    </h2>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="position-relative">
                                        <div class="FSD_animation">
                                            {/* <img src={table1} className="position-absolute" alt="" id="table1" /> */}
                                            {/* <img src={table2} className="position-absolute" alt="" id="table2" /> */}
                                            {/* <img src={table3} className="position-absolute" alt="" id="table3" /> */}
                                            {/* <img src={table4} className="position-absolute" alt="" id="table4" /> */}
                                            {/* <img src={table5} className="position-absolute" alt="" id="table5" /> */}
                                            {/* <img src={table6} className="position-absolute" alt="" id="table6" /> */}
                                            {/* <img src={table7} className="position-absolute" alt="" id="table7" /> */}
                                            {/* <img src={table8} className="position-absolute" alt="" id="table8" /> */}
                                            {/* <img src={table9} className="position-absolute" alt="" id="table9" /> */}
                                            {/* <img src={table10} className="position-absolute" alt="" id="table10" /> */}
                                            {/* <img src={table11} className="position-absolute" alt="" id="table11" /> */}
                                            {/* <img src={table12} className="position-absolute" alt="" id="table12" /> */}
                                            {/* <img src={table13} className="position-absolute" alt="" id="table13" /> */}
                                            {/* <img src={table14} className="position-absolute" alt="" id="table14" /> */}
                                            {/* <img src={table15} className="position-absolute" alt="" id="table15" /> */}
                                            {/* <img src={table16} className="position-absolute" alt="" id="table16" /> */}
                                            {/* <img src={table17} className="position-absolute" alt="" id="table17" /> */}
                                            {/* <img src={table18} className="position-absolute" alt="" id="table18" /> */}
                                            {/* <img src={table19} className="position-absolute" alt="" id="table19" /> */}
                                            <img src={table20} className="position-absolute" alt="" id="table20" />
                                        </div>
                                    </div> 
                                    <div className="row no-gutters">
                                        <div className="col-xl-10 col-lg-10 col-md-11 col-11 Complex-Information-divider page_divider wow animate__fadeInLeft animate__faster animate__animated overflowX" data-wow-delay=".2s">
                                            <div className="row">
                                                <div className="offset-xl-3 col-xl-8 offset-lg-2 col-lg-9 offset-md-2 col-md-9">
                                                    <h3 className="Complex-Information-Security-By-Design wow animate__fadeIn animate__faster animate__animated" data-wow-delay=".4s">
                                                        Complex Information <span className="text-style">Security</span> By Design Data {'&'} System Integrations
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="row no-gutters justify-content-center">
                    <div className="col-xl-8 col-lg-9 col-md-9 text-center overflowX d-flex justify-content-center">
                        <div className="section-wrapper">
                            <h3 className="wow animate__slideInLeft animate__faster animate__animated" data-wow-delay=".4s">According to OWASP, the Top 10 API Security Risks are:</h3>
                            <div className="row no-gutters">
                                <div className="col-md-9">
                                    <div className="text-left wow animate__slideInLeft animate__faster animate__animated" data-wow-delay=".4s">
                                        <ul className="p-0">
                                            <li><span className="Order-list-number">1.</span> Broken Object Level Access Control</li>
                                            <li><span className="Order-list-number">2.</span>  Broken Authentication</li>
                                            <li><span className="Order-list-number">3.</span>  Improper Data Filtering </li>
                                            <li><span className="Order-list-number">4.</span>  Lack of Resources and Rate Limiting </li>
                                            <li><span className="Order-list-number">5.</span>  Missing Function/Resource Level Access Control</li>
                                            <li><span className="Order-list-number">6.</span>  Mass Assignment </li>
                                            <li><span className="Order-list-number">7.</span>
                                            Security Misconfiguration
                                            <img src={owasp} alt="owasp-img" className="owasp-img" />
                                            </li>
                                            <li><span className="Order-list-number">8.</span>  Injection</li>
                                            <li><span className="Order-list-number">9.</span>  Improper Assets Management</li>
                                            <li><span className="Order-list-number">10.</span>  Insufficient Logging and Monitoring </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <img src={owasp} alt="owasp-img" className="mobile-owasp-img" />
                                    <img src={Owsap} alt="" className="Owsap-image" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-3 col-md-4">
                </div>     */}
                </div>
                <div className="page_bottom_divider_banner overflowX">
                    <h3 className="Proud-Member-of-the-Following-Organizations p-4 wow animate__fadeIn animate__animated" data-wow-delay=".2s">Proud <span className="color_color1">Member</span> of the Following Organizations:</h3>
                    <div className="row no-gutters justify-content-start wow animate__slideInLeft animate__faster animate__animated" data-wow-delay=".8s">
                        <div className="col-xl-10 col-lg-10 col-md-10 logo_divider_row d-none d-md-block d-lg-block d-xl-block">
                            <div className="row justify-content-end">
                                <div className="offset-xl-2 col-xl-push-8 offset-lg-3 col-lg-9  offset-md-3 col-md-9 d-flex justify-content-center align-items-center">
                                    <img src={shape_19} className="image image_PML_img wow animate__fadeIn animate__animated" data-wow-delay="1s" alt="" />
                                    <img src={shape_20} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.1s" alt="" />
                                    <img src={shape_21} className="image image_w150 wow animate__fadeIn animate__animated" data-wow-delay="1.2s" alt="" />
                                    <img src={shape_22} className="image image_w160 wow animate__fadeIn animate__animated" data-wow-delay="1.3s" alt="" />
                                    <img src={shape_23} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.4s" alt="" />
                                    <img src={shape_24} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.5s" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 logo_divider_row d-sm-block d-md-none d-lg-none d-xl-none">
                            <div className="d-flex justify-content-around align-items-end">
                                <img src={shape_19} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1s" alt="" />
                                <img src={shape_20} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.1s" alt="" />
                                <img src={shape_21} className="image image_w150 wow animate__fadeIn animate__animated" data-wow-delay="1.2s" alt="" />
                            </div>
                            <div className="d-flex justify-content-around align-items-end">
                                <img src={shape_22} className="image image_w160 wow animate__fadeIn animate__animated" data-wow-delay="1.3s" alt="" />
                                <img src={shape_23} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.4s" alt="" />
                                <img src={shape_24} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.5s" alt="" />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default withRouter(CustomPage);