
import {
    StandardMaterial, SceneLoader,Vector3, MeshBuilder, Animation, Texture, Color3,
    Color4, TransformNode, Matrix, VertexData, Mesh, Scalar, FresnelParameters, HighlightLayer, GlowLayer, Engine
} from '@babylonjs/core';

export class MainSceneCreator extends TransformNode {
//https://playground.babylonjs.com/#J6ZNXJ#90
    _starData;

    _radius;

    _starLimit; // Show this many of the brightest stars as mapped triangles.
    _starScale; // 0.4 // Size of largest star (larger/brighter stars are factors of this number).

    _showAsterisms;
    _asterismColor;

    _twinkleStars = true;

    constructor(name, scene, starData, radius, starLimit, starScale, showAsterisms, asterismColor, twinkleStars) {

        super(name, scene);

        this._starData = starData;
        this._radius = radius;
        this._starLimit = starLimit;
        this._starScale = starScale;
        this._showAsterisms = showAsterisms;
        this._asterismColor = asterismColor;
        this._twinkleStars = twinkleStars;

        // Add empty celestial sphere mesh.
        let starMesh = new Mesh('starMesh', scene);
        starMesh.parent = this;
        starMesh.alphaIndex = 20;

        // Mesh vertex data arrays.
        let positions = [];
        let indices = [];
        let colors = [];
        let uvs = [];
        let uvs2 = [];

        let vertexIndex = 0;
        let numberOfStars = Math.min(starData.rightAscension.length, this._starLimit);

        //==ATMOSPHERE Layer Material==//
        var materialAtmosphere = new StandardMaterial('atmosphereLayer', scene);
        materialAtmosphere.diffuseColor = new Color3(0.29, 0.63, 1);
        //materialAtmosphere.specularTexture = new Texture("https://raw.githubusercontent.com/mediamachina/testBab/main/1503_Clouds_Specular.jpg", scene);
        materialAtmosphere.emissiveColor = new Color3(0.31, 0.52, 0.97);
        materialAtmosphere.alpha = 0.2;

        // Fresnel
        materialAtmosphere.emissiveFresnelParameters = new FresnelParameters();
        materialAtmosphere.emissiveFresnelParameters.bias = 0.7;
        materialAtmosphere.emissiveFresnelParameters.power = 5;
        materialAtmosphere.emissiveFresnelParameters.leftColor = Color3.White();
        materialAtmosphere.emissiveFresnelParameters.rightColor = Color3.Black();


        //==CLOUDS Layer Material==//

        var materialClouds = new StandardMaterial('spheremat', scene);
        materialClouds.diffuseTexture = new Texture("https://raw.githubusercontent.com/mediamachina/testBab/main/1503_Clouds_Diffuse2.jpg", scene);
        materialClouds.bumpTexture = new Texture("https://raw.githubusercontent.com/mediamachina/testBab/main/1503Clouds_Normal.jpg", scene);
        materialClouds.specularTexture = new Texture("https://raw.githubusercontent.com/mediamachina/testBab/main/1503_Clouds_Specular.jpg", scene);
        //materialClouds.diffuseTexture.hasAlpha = true;
        //materialClouds.backFaceCulling = false;
        //materialClouds.useAlphaFromDiffuseTexture = true;
        materialClouds.opacityTexture = materialClouds.diffuseTexture;
        materialClouds.alphaMode = Engine.ALPHA_ADD;
        //materialClouds.specularColor = Color3.Black();
        //Have no idea why but neither specular texture neither above line are not topping hemisphere to leave a big light spot...

        //==PLANET Earth Material==//
        var earthMaterial = new StandardMaterial("earthMaterial", scene);
        //earthMaterial.diffuseTexture = new Texture("https://raw.githubusercontent.com/mediamachina/testBab/main/1503_Clouds_Diffuse2.jpg", scene);
        earthMaterial.diffuseTexture = new Texture("https://raw.githubusercontent.com/mediamachina/testBab/main/Albedo1.jpg", scene);
        earthMaterial.emissiveColor = new Color3(0.17, 0.67, 1);
        earthMaterial.specularColor = new Color3(0.26, 0.26, 0.26);

        earthMaterial.emissiveFresnelParameters = new FresnelParameters();
        earthMaterial.emissiveFresnelParameters.bias = 0.5;
        earthMaterial.emissiveFresnelParameters.power = 4;
        earthMaterial.emissiveFresnelParameters.leftColor = new Color3.White();
        earthMaterial.emissiveFresnelParameters.rightColor = new Color3(0.34, 0.34, 0.34);
        //1503_Clouds_Diffuse2withTransparency.png
        //earthMaterial.diffuseTexture = new Texture("https://raw.githubusercontent.com/mediamachina/testBab/main/1503_Clouds_Diffuse2withTransparency.png", scene);
       // earthMaterial.bumpTexture = new Texture("https://raw.githubusercontent.com/mediamachina/testBab/main/1503Earth_Normal.jpg", scene);
        //earthMaterial.specularColor = Color3.Black();
       // earthMaterial.alphaMode = Engine.ALPHA_ADD;
       

        //==MOON Material==//
        var moonMaterial = new StandardMaterial("moonMaterial", scene);
        moonMaterial.diffuseTexture = new Texture("https://raw.githubusercontent.com/mediamachina/testBab/main/RMoon_Diffuse.jpg", scene);
        moonMaterial.bumpTexture = new Texture("https://raw.githubusercontent.com/mediamachina/testBab/main/RMoon_Normal.jpg", scene);
        moonMaterial.specularColor = Color3.Black();



        //---------------------------------------------Meshes-----------------------------------------------//

        //==ATMOSPHERE Layer MESH==//
        // var atmosphereLayer = Mesh.CreateSphere("atmosphereLayer", 39, 18, scene);
        // atmosphereLayer.position.y = -10;
        // atmosphereLayer.material = materialAtmosphere;

        // var hlA = new HighlightLayer("hlA", scene);
        // hlA.addMesh(atmosphereLayer, new Color3(0.67, 0.83, 1));
        // hlA.blurHorizontalSize = 1.5;
        // hlA.blurVerticalSize = 3;


        // var glAtmosphere = new GlowLayer("glowEarth", scene, {
        //     mainTextureFixedSize: 5,
        //     blurKernelSize: 128

        // });

        // glAtmosphere.intensity = 0.3;

        //==CLOUDS Layer MESH==//
        //  var CloudsLayer = Mesh.CreateSphere("Clouds", 18.6, 18.7, scene);
        //  CloudsLayer.material = materialClouds;
        //  CloudsLayer.position.y = -10;

        //==EARTH MESH==//

        var Earth = MeshBuilder.CreateSphere("Earth", { diameter: 24, segments: 32 }, scene);

        var glEarth = new GlowLayer("glowEarth", scene, {
            mainTextureFixedSize: 512,
            blurKernelSize: 128
        });

         glEarth.intensity = 0.1;

        // Move the sphere upward 1/2 its height
        Earth.position.y = -12;
        Earth.rotation.x = 1.2 * Math.PI;
        Earth.rotation.y = Math.PI / -6;
        Earth.material = earthMaterial;
        var hlE = new HighlightLayer("hlM", scene);
        hlE.addMesh(Earth, new Color3(0.61, 0.83, 0.97, 0.23));
       

        //==MOON MESH==//
        var Moon = MeshBuilder.CreateSphere("Moon", { diameter: 3, segments: 32 }, scene);
        Moon.position.y = 1;
        Moon.position.x = 7;
        Moon.rotation.x = 1.2 * Math.PI;
        Moon.rotation.y = Math.PI / -6;

        Moon.material = moonMaterial;
       
        //==ASTROID MESH===//

        SceneLoader.ImportMesh("", "/model-assets/", "Asteroid1.babylon", scene, function (newMeshes) {
            const meshRecord = newMeshes[0];
            meshRecord.position = new Vector3(5, 0, -4.5);
            meshRecord.scaling = new Vector3(1, 1, 1);
            meshRecord.rotate.x = Math.PI /2;
            });
            SceneLoader.ImportMesh("", "/model-assets/", "Asteroid2.babylon", scene, function (newMeshes) {
            const meshRecord = newMeshes[0];
            meshRecord.position = new Vector3(-4, 0, 4.5);
            meshRecord.scaling = new Vector3(1,1,1);
            //meshRecord.rotate.x = Math.PI /4;
            });
        SceneLoader.ImportMesh("", "/model-assets/", "Asteroid3.babylon", scene, function (newMeshes) {
            const meshRecord = newMeshes[0];
            meshRecord.position = new Vector3(-4, 1, -4.5);
            meshRecord.scaling = new Vector3(1, 1, 1);
            meshRecord.rotate.x = Math.PI /6;
            });
        SceneLoader.ImportMesh("", "https://raw.githubusercontent.com/mediamachina/testBab/main/", "Asteroid.glb", scene, function (newMeshes) {
            const meshRecord = newMeshes[0];
            meshRecord.position = new Vector3(-12, 9, 7);
            meshRecord.scaling = new Vector3(2, 2, 2);
            meshRecord.rotate.x = Math.PI ;
            });
        //==END ASTRroid MESH==//

        
        // var hlM = new HighlightLayer("hlM", scene);
        // hlM.addMesh(Moon, new Color3(0.87, 0.93, 1, 0.23));
        // hlM.blurHorizontalSize = 2;
        // hlM.blurVerticalSize = 2;
        // hlM.innerGlow = true;

        //EXCLUDE (to avoid display bug)
        //hlA.addExcludedMesh(CloudsLayer, Earth);
        
        //Animate the Wheels
        const animWheel = new Animation("wheelAnimation", "rotation.y", 5, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);

        const wheelKeys = [];

        //At the animation key 0, the value of rotation.y is 0
        wheelKeys.push({
            frame: 0,
            value: 0
        });

        wheelKeys.push({
            frame: 600,
            value: 2 * Math.PI
        });

        //set the keys
        animWheel.setKeys(wheelKeys);

        //Link this animation to a wheel
      //  Earth.animations = [];
        //Earth.animations.push(animWheel);

        // CloudsLayer.animations = [];
        // CloudsLayer.animations.push(animWheel);

        //Link this animation to a wheel
        Moon.animations = [];
        Moon.animations.push(animWheel);

      //  scene.beginAnimation(Earth, 0, 300, true);
       // scene.beginAnimation(CloudsLayer, 0, 300, true);
       // scene.beginAnimation(Moon, 0, 300, true);



        // const wheelMat = new StandardMaterial("wheelMat");
        // wheelMat.diffuseTexture = new Texture("https://les.mitsubishielectric.co.uk/assets/Uploads/328a039bfe/Changing-view-from-space.jpg", scene);

        // const wheelRB = MeshBuilder.CreateSphere("wheelRB", { diameter: 6 });
        // wheelRB.material = wheelMat;
        // wheelRB.setAbsolutePosition(new Vector3(0, -3.9, 1));

        // const wheelMat1 = new StandardMaterial("wheelMat");
        // wheelMat1.diffuseTexture = new Texture("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9JPpDIKsmt5TPDs1YPK2EB98CqGSa0f9AsA&usqp=CAU", scene);

        // const wheelRB1 = MeshBuilder.CreateSphere("wheelRB1", { diameter: 0.32 });
        // wheelRB1.material = wheelMat1;
        // wheelRB1.setAbsolutePosition(new Vector3(0.75, 0.05, 0));

        // //Animate the Wheels
        // const animWheel = new Animation("wheelAnimation", "rotation.y", 5, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);

        // const wheelKeys = [];

        // //At the animation key 0, the value of rotation.y is 0
        // wheelKeys.push({
        //     frame: 0,
        //     value: 0
        // });

        // wheelKeys.push({
        //     frame: 300,
        //     value: 2 * Math.PI
        // });

        // //set the keys
        // animWheel.setKeys(wheelKeys);

        // //Link this animation to a wheel
        // wheelRB.animations = [];
        // wheelRB.animations.push(animWheel);

        // //Link this animation to a wheel
        // wheelRB1.animations = [];
        // wheelRB1.animations.push(animWheel);

        // scene.beginAnimation(wheelRB, 0, 300, true);

        // scene.beginAnimation(wheelRB1, 0, 300, true);

        // Populate vertex data arrays for each star.
        for (let starIndex = 0; starIndex < numberOfStars; starIndex++) {

            // Star celestial coordinates.
            let ra = this._starData.rightAscension[starIndex]; // eastward in radians (around Y axis - yaw)
            let dec = this._starData.declination[starIndex]; // north-south in radians (around X axis - pitch)

            // Star scale factor (based on apparent magnitude).
            var s = this._starScaleFactor(starIndex);

            // Create star vertices around +Z axis & scale to size.
            let v1 = new Vector3(0.0 * s, 0.7 * s, this._radius);
            let v2 = new Vector3(-0.5 * s, -0.3 * s, this._radius);
            let v3 = new Vector3(0.5 * s, -0.3 * s, this._radius);

            // Rotate vertices into position on celestial sphere.
            let rotationMatrix = Matrix.RotationYawPitchRoll(-ra, -dec, 0);
            v1 = Vector3.TransformCoordinates(v1, rotationMatrix);
            v2 = Vector3.TransformCoordinates(v2, rotationMatrix);
            v3 = Vector3.TransformCoordinates(v3, rotationMatrix);

            // Add vertex positions.
            positions.push(
                v1.x, v1.y, v1.z,
                v2.x, v2.y, v2.z,
                v3.x, v3.y, v3.z
            );

            // Add vertex color.
            let c = this._starColor(starIndex);
            colors.push(
                c.r, c.g, c.b, c.a,
                c.r, c.g, c.b, c.a,
                c.r, c.g, c.b, c.a
            );

            // Add star texture UV coordinates.
            uvs.push(0.5, 1.0, 0.0, 0.0, 1.0, 0.0);

            // Add 'twinkle' (noise) texture UV coordinates.
            let u = Math.random();
            let v = Math.random();
            uvs2.push(u, v, u, v, u, v);

            // Add indices.
            indices.push(vertexIndex, vertexIndex + 1, vertexIndex + 2);
            vertexIndex += 3;
        }

        // Create & assign vertex data to mesh.
        let vertexData = new VertexData();
        vertexData.positions = positions;
        vertexData.indices = indices;
        vertexData.colors = colors;
        vertexData.uvs = uvs;
        vertexData.uvs2 = uvs2;
        vertexData.applyToMesh(starMesh);

        // Create & assign star material.        
        let starMaterial = new StandardMaterial('starMaterial', scene);
        let opacityTexture = new Texture('/model-assets/star.png', scene);
        starMaterial.opacityTexture = opacityTexture;
        starMaterial.disableLighting = true;
        starMesh.material = starMaterial;

        // Twinkle stars (simulate atmospheric turbulence).
        if (this._twinkleStars) {

            let emissiveTexture = new Texture('/model-assets/noise.png', scene);
            starMaterial.emissiveTexture = emissiveTexture;
            emissiveTexture.coordinatesIndex = 1; // uvs2            

            // Animate emissive texture to simulate star 'twinkle' effect.
            scene.registerBeforeRender(() => {
                emissiveTexture.uOffset += 0.008;
            });
        }
        else {
            starMaterial.emissiveColor = new Color3(1, 1, 1);
        }

        // Draw celestial equator.
        let points = [];
        let steps = 100;
        for (let i = 0; i < steps + 1; i++) {

            let a = (Math.PI * 2 * i / steps);
            let x = Math.cos(a) * this._radius;
            let y = 0;
            let z = Math.sin(a) * this._radius;

            points.push(new Vector3(x, y, z));
        }

        radius += 20;
        //Array of paths to construct tube
        let c = 2 * Math.PI * radius;
        let h = c / 4 / 2;
        let myPath = [
            new Vector3(0, 0, h),
            new Vector3(0, 0, -h)
        ];

        //Create ribbon with updatable parameter set to true for later changes
        let tubeParentXform = new TransformNode('tubeParentXform', scene);
        let tubeChildXform = new TransformNode('tubeChildXform', scene);
        let tube = MeshBuilder.CreateTube("tube", { path: myPath, radius: radius, sideOrientation: Mesh.BACKSIDE, updatable: false }, scene);
        tube.alphaIndex = 0;
        let tubeTexture = new Texture("/model-assets/milky-way.png", scene, true, false);
        tubeTexture.vScale = -1;
        tube.parent = tubeChildXform;
        tubeChildXform.parent = tubeParentXform
        tube.rotate(new Vector3(0, 0, -1), 0.57);
        tubeChildXform.rotate(new Vector3(1, 0, 0), 0.48);
        tubeParentXform.rotate(new Vector3(0, -1, 0), 0.22);
        let tubeMaterial = new StandardMaterial("skyBox", scene);
        tubeMaterial.backFaceCulling = true;
        tubeMaterial.disableLighting = true;
        tubeMaterial.emissiveTexture = tubeTexture;
        tube.material = tubeMaterial;
        tube.material.alpha = 0.5;
        tubeParentXform.parent = this;
    }

    /*
     *  Look-up star color using star's color index B-V.
     * 
     *  See: https://en.wikipedia.org/wiki/Color_index
     *  Blue-white-red star color range from http://www.vendian.org/mncharity/dir3/starcolor/details.html
     */
    _starColor(starIndex) {

        // Normalize star color fraction from colorIndexBV range of -0.4-2.0 to 0.0-1.0.
        let fraction = Scalar.Normalize(this._starData.colorIndexBV[starIndex], -0.4, 2.0);

        // Calculate star color index.
        let maxColorIndex = this._starData.color.length - 1;
        let colorIndex = Math.round(maxColorIndex * fraction);
        colorIndex = Scalar.Clamp(colorIndex, 0, maxColorIndex);

        // Look-up and return star color.
        let c = this._starData.color[colorIndex];
        return new Color4(c[0], c[1], c[2], 0);
    }

    /*
     *  Compute star scale factor based on apparent magnitude.
     */
    _starScaleFactor(starIndex) {

        // Magnitude is counterintuitive - lower values are hgiher magnitudes!
        // "Lowest" magnitude in star data is 7.8, "highest" is -1.44 for Sirius.
        // So we need to invert these & ensure positive to get scale that approximates magnitude.
        return (8 - this._starData.apparentMagnitude[starIndex]) * this._starScale;
    }
}