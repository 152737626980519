import React from 'react';
import cocacola from '../assets/images/cocacola.png';
import kelloggs from '../assets/images/kelloggs.png';
import jpmorgan from '../assets/images/jpmorgan.png';
import charles from '../assets/images/charles.png';
import experian from '../assets/images/experian.png';
// import violettwitterIcon from '../assets/images/violet-color-twitter.svg';
// import violetfacebookIcon from '../assets/images/violet-color-facebbok.svg';
// import violetlinkedInIcon from '../assets/images/violet-color-linkedIn.svg'; 
import Semicircle from '../assets/images/Semicircle.svg';
import site_logo_light from '../assets/images/Logo-cto.svg';

import Step1 from '../assets/images/Step1-cto.svg';
import Step1B from '../assets/images/Step1B.svg';
import Arrow1 from '../assets/images/Arrow1.svg';
import Arrow2 from '../assets/images/Arrow2.svg';
import Step2 from '../assets/images/Step2-cto.svg';
import Step2B from '../assets/images/Step2B.svg';
import Step3 from '../assets/images/Step3-cto.svg';
import Arrow3 from '../assets/images/Arrow3.svg';
import Step4 from '../assets/images/Step4-cto.svg';
import Arrow4 from '../assets/images/Arrow4.svg';
import Step5 from '../assets/images/Step5-cto.svg';
import Mobile_Arrow from '../assets/images/ArrowForMobileVersion.svg';
import shape_19 from '../assets/images/shape-19.png';
import shape_20 from '../assets/images/shape-20.png';
import shape_21 from '../assets/images/shape-21.png';
import shape_22 from '../assets/images/shape-22.png';
import shape_23 from '../assets/images/shape-23.png';
import shape_24 from '../assets/images/shape-24.png'; 
// import twitterIcon from '../assets/images/cto-twitter.svg';
// import facebookIcon from '../assets/images/cto-facebook.svg';
// import linkedInIcon from '../assets/images/cto-linkedin.svg';
import InactiveTab from '../assets/images/CTOInactiveTab.svg';
import ActiveTab from '../assets/images/CTOactiveTab.svg';
// import SubNavigation from '../components/Sub-navigation';
import { Link, withRouter } from 'react-router-dom';

import { getFromStorage, removeFromStorage } from '../common/service';
import { CONSTANTS } from '../constants';
import { API_ENDPOINTS, AUTH_HEADER_KEY, PLAIN_HEADERS } from '../constants/api-endpoints';
import { apiCall } from '../common/NetworkCalls';
import CiircleBg from '../assets/images/CircleBlue-cto.svg';

//Heads Animation
import head1 from '../assets/images/Animation/Heads/heads-01.svg';
import head2 from '../assets/images/Animation/Heads/heads-02.svg';
import head3 from '../assets/images/Animation/Heads/heads-03.svg';
import head4 from '../assets/images/Animation/Heads/heads-04.svg';
import head5 from '../assets/images/Animation/Heads/heads-05.svg';
import head6 from '../assets/images/Animation/Heads/heads-06.svg';
import head7 from '../assets/images/Animation/Heads/heads-07.svg';
import head8 from '../assets/images/Animation/Heads/heads-08.svg';
import head9 from '../assets/images/Animation/Heads/heads-09.svg';
import head10 from '../assets/images/Animation/Heads/heads-10.svg';

//Code Animation
import code1 from '../assets/images/Animation/code/code-01.svg';
import code2 from '../assets/images/Animation/code/code-02.svg';
import code3 from '../assets/images/Animation/code/code-03.svg';
import code4 from '../assets/images/Animation/code/code-04.svg';
import code5 from '../assets/images/Animation/code/code-05.svg';
import code6 from '../assets/images/Animation/code/code-06.svg';
import code7 from '../assets/images/Animation/code/code-07.svg';

//Ekierka Animation 
import Ekierka1 from '../assets/images/Animation/Ekierka/Ekierka-01.svg';
import Ekierka2 from '../assets/images/Animation/Ekierka/Ekierka-02.svg';
import Ekierka3 from '../assets/images/Animation/Ekierka/Ekierka-03.svg';
import Ekierka4 from '../assets/images/Animation/Ekierka/Ekierka-04.svg';
import Ekierka5 from '../assets/images/Animation/Ekierka/Ekierka-05.svg';
import Ekierka6 from '../assets/images/Animation/Ekierka/Ekierka-06.svg';

//Labirynth Animation
import Labirynth1 from '../assets/images/Animation/Labirynth/Labirynth-01.svg';
import Labirynth2 from '../assets/images/Animation/Labirynth/Labirynth-02.svg';
import Labirynth3 from '../assets/images/Animation/Labirynth/Labirynth-03.svg';
import Labirynth4 from '../assets/images/Animation/Labirynth/Labirynth-04.svg';
import Labirynth5 from '../assets/images/Animation/Labirynth/Labirynth-05.svg';
import Labirynth6 from '../assets/images/Animation/Labirynth/Labirynth-06.svg';
import Labirynth7 from '../assets/images/Animation/Labirynth/Labirynth-07.svg';
import Labirynth8 from '../assets/images/Animation/Labirynth/Labirynth-08.svg';

//Card Animation 
import card1 from '../assets/images/Animation/card/card-01.svg';
import card2 from '../assets/images/Animation/card/card-02.svg';
import card3 from '../assets/images/Animation/card/card-03.svg';
import card4 from '../assets/images/Animation/card/card-04.svg';
import card5 from '../assets/images/Animation/card/card-05.svg';
import card6 from '../assets/images/Animation/card/card-06.svg';
import card7 from '../assets/images/Animation/card/card-07.svg';
import card8 from '../assets/images/Animation/card/card-08.svg';

//Brain
import Brain1 from '../assets/images/Animation/AI/Brain-01.svg';
import Brain2 from '../assets/images/Animation/AI/Brain-02.svg';
import Brain3 from '../assets/images/Animation/AI/Brain-03.svg';
import Brain4 from '../assets/images/Animation/AI/Brain-04.svg';
import Brain5 from '../assets/images/Animation/AI/Brain-05.svg';
import Brain6 from '../assets/images/Animation/AI/Brain-06.svg';


class CTOPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserLoggedIn: false,
            userInfo: ''
        }
    }

    componentDidMount() {
        var userData = getFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        console.log(userData)
        if (userData) {
            userData = JSON.parse(userData);
            this.setState({ isUserLoggedIn: true, userInfo: userData });
        }
    }

    logOutUser() {
        this.setState({ isUserLoggedIn: false, userInfo: '' });
        removeFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        var headers = PLAIN_HEADERS;
        headers[AUTH_HEADER_KEY] = `Bearer ${this.state.userInfo.authToken}`;
        return apiCall(API_ENDPOINTS.LOGOUT, 'GET', '',).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {
        const pathUrl = window.location.hash;

        return (
            <div>
                <nav className="navbar navbar-expand-md navbar-dark bg_cto_theme top_navbar">
                    <div className="container top_nav">
                        <Link className="navbar-brand d-none d-md-block d-lg-block d-xl-block top-nav-logo" to={'/'} >
                            <img src={site_logo_light} alt="" className="cto-site-logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="navbar-collapse">
                            <ul className="nav nav-tabs mr-auto tabs-section" id="myTab" role="tablist">
                                <li className="inactive-tab-listitem">
                                    <Link to={'/'}  >
                                        <img src={InactiveTab} alt="" className="CTOTab-inactive" />
                                        <label className="TabLabel TabLabelCTOInactiveTab-label">TRUSTED DEV PORTAL</label>
                                    </Link>
                                </li>
                                <li className="active-tab-listitem">
                                    <Link to={'cto'}  >
                                        <img src={ActiveTab} alt="" className="CTOTab-active" />
                                        <label className="TabLabel TabLabelCTOActiveTab-label">CTO AS A SERVICE PORTAL</label>
                                    </Link>
                                </li>
                            </ul>
                            <div className="d-none d-md-block d-lg-block d-xl-block">
                                <ul className="navbar-nav">
                                    {/* <div className="d-flex margin-right">
                                    <li className="nav-item padding8">
                                        <a className="nav-link p-0" href="/#"> 
                                            <img src={ twitterIcon } alt="twitter" className="social-twitter-icon" />
                                        </a>
                                    </li>
                                    <li className="nav-item padding8">
                                        <a className="nav-link p-0" href="/#"> 
                                            <img src={ facebookIcon } alt="facebook" className="social-facebook-icon" />
                                        </a>
                                    </li> 
                                    <li className="nav-item padding8">
                                        <a className="nav-link p-0" href="/#"> 
                                            <img src={ linkedInIcon } alt="linkedIn" className="social-linkedIn-icon" />
                                        </a>
                                    </li> 
                                </div>
                                <span className="color_color4 font12">|</span> */}
                                    <div className="d-flex margin-left login-link-font">
                                        <li className="nav-item">
                                            {this.state.isUserLoggedIn ?
                                                <span className="d-flex align-items-center text-light font14 login-link-font">
                                                    <span className="">{this.state.userInfo && `${this.state.userInfo.first_name} ${this.state.userInfo.last_name}`}&nbsp;</span>
                                                    <button className="btn btn-link font-weight600 text_underline color_color1" onClick={() => {
                                                        this.logOutUser();
                                                    }}> Log out</button>
                                                </span>
                                                :
                                                <span className="d-flex text-light font14 login-link-font">
                                                    <span className="">Already a Member? &nbsp;</span>
                                                    <Link to={'login'} className="font-weight600 text_underline color_color_light_red"> Log in</Link>
                                                </span>
                                            }
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="text-light ctoServicePortal-tab">
                    <div className="cto-page-banner">
                        {/* <SubNavigation />  */}
                        <nav className="navbar navbar-expand-md font12 sub_nav collapse navbar-collapse sticky" id="navbarSupportedContent">
                            <div className="container sub-menu-width">
                                <div className="row no-gutters w-100 nav-row d-sm-flex d-md-none d-lg-none d-xl-none">
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <button className="nav-link cto_hire_button btn font10 font-weight600 btn-sm capital_letter"
                                            onClick={() => {
                                                this.props.history.push("/basic-info");
                                            }}
                                            disabled={this.state.isUserLoggedIn}
                                        >
                                            Hire Top Talent
                                        </button>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <div className="nav-vertical-line">
                                            <div className="d-flex flex-column text-center">
                                                {this.state.isUserLoggedIn ?
                                                    <div className="d-flex align-items-baseline justify-content-center font14 login-link-font">
                                                        <span className="">{this.state.userInfo && `${this.state.userInfo.first_name} ${this.state.userInfo.last_name}`}&nbsp;</span>
                                                        <button className="btn btn-link font-weight600 text_underline color_color1" onClick={() => {
                                                            this.logOutUser();
                                                        }}> Log out</button>
                                                    </div>
                                                    :
                                                    <>
                                                        <span className="already-member">Already a Member?</span>
                                                        <Link to={'login'} className="login-link">Log in</Link>
                                                    </>
                                                }
                                            </div></div>
                                    </div>
                                </div>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'cto'}>
                                            <span className={pathUrl === "#/cto" ? "active_nav" : ""}>Home</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'why'}>
                                            <span className={pathUrl === "#/why" || pathUrl === "#/how" || pathUrl === "#/what" ? "active_nav" : ""}>Why Us?</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'ai'}>
                                            <span className={pathUrl === "#/ai" ? "active_nav" : ""}>AI and Vetted Software Engineers</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'engagement'}>
                                            <span className={pathUrl === "#/engagement" ? "active_nav" : ""}>Our Engagement Model</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={'customsaas'}>
                                            <span className={pathUrl === "#/customsaas" ? "active_nav" : ""}>Enterprise Software Development</span>
                                        </Link>
                                    </li>
                                </ul>
                                {/* <div className="row no-gutters w-100 d-sm-block d-md-none d-lg-none d-xl-none">
                                    <div className="col-md-2 d-flex align-items-center justify-content-center my-5"> 
                                        <div className="mx-2"> 
                                            <a href="/#">
                                                <img src={ navbarTwitter } className="social-media" alt="" />
                                            </a>
                                        </div>
                                        <div className="mx-2">
                                            <a href="/#">
                                                <img src={ navbarFacebook } className="social-media" alt="" />
                                            </a>
                                        </div>
                                        <div className="mx-2">
                                            <a href="/#">
                                                <img src={ navbarLinkedIn } className="social-media" alt="" />
                                            </a>
                                        </div>
                                    </div>  
                                </div> */}
                                <button className="nav-link d-none d-md-block d-lg-block d-xl-block hire_button btn font10 font-weight600 btn-sm capital_letter"
                                    onClick={() => {
                                        this.props.history.push("/basic-info");
                                    }}
                                    disabled={this.state.isUserLoggedIn}
                                >
                                    Hire Top Talent
                                </button>
                            </div>
                        </nav>
                        <div className="row no-gutters">
                            <div className="col-md-12 header-background-image">
                                <div className="text-center top-space">
                                    <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                                        <img src={site_logo_light} alt="" className="mobile-banner-logo wow animate__animated animate__zoomIn" data-wow-delay=".2s" />
                                        <div className="longdash-sm wow animate__animated animate__zoomIn" data-wow-delay=".4s"></div>
                                    </div>
                                    <h1 className="font40 banner_heading heading_font wow animate__animated animate__fadeInDown" data-wow-delay=".3s">
                                        The Trusted Dev CTO as a Service Portal is designed to solve your most complex technical and business challenges with vetted CTO caliber talent on demand.
                                    </h1>
                                    <div className="longdash longdash_lightRed wow animate__animated animate__zoomIn" data-wow-delay=".4s"></div>
                                    <p className="banner_paragraph wow animate__animated animate__zoomIn" data-wow-delay=".5s">
                                        We vet and retain CTO caliber talent to lead the most strategic and complex initiatives in your information technology project portfolio.
                                    </p>
                                    <button className="nav-link mobile_hire_button orange-color d-md-none d-lg-none d-xl-none btn font18 font-weight700 btn-sm capital_letter animate__animated animate__fadeInUp animate__delay-0.9s"
                                        onClick={() => {
                                            this.props.history.push("/basic-info");
                                        }}
                                        disabled={this.state.isUserLoggedIn}
                                    >
                                        Hire Top Talent
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-xl-block d-lg-block d-md-block bottom-margin">
                            <div className="row justify-content-center no-gutters banner-content">
                                <div className="col-md-12">
                                    <div className="row no-gutters"> 
                                        <div className="lines-wrapper">
                                            <div className="center-circle-lines">
                                                {/* Step 1 */}
                                                <div className="wow animate__animated animate__fadeIn" data-wow-delay=".4s">
                                                    <span className="banner-step banner-step-number1">01</span>
                                                    <img src={CiircleBg} className="banner-image banner-image-step1" alt="" />
                                                    <div class="Animations_Card">
                                                        <img src={card1} className="position-absolute" alt="" id="frame1" /> 
                                                        <img src={card2} className="position-absolute" alt="" id="frame2" /> 
                                                        <img src={card3} className="position-absolute" alt="" id="frame3" /> 
                                                        <img src={card4} className="position-absolute" alt="" id="frame4" /> 
                                                        <img src={card5} className="position-absolute" alt="" id="frame5" /> 
                                                        <img src={card6} className="position-absolute" alt="" id="frame6" /> 
                                                        <img src={card7} className="position-absolute" alt="" id="frame7" /> 
                                                        <img src={card8} className="position-absolute" alt="" id="frame8" /> 
                                                    </div>
                                                    <h5 className="banner-heading banner-heading-step1">Identify</h5>
                                                    <p className="banner-content banner-content-step1">
                                                        We identify CTO caliber talent from a technical, creative problem solving, communication and tailored culture fit perspective.
                                                    </p>
                                                </div>
                                                {/* Step 2 */}
                                                <div className="wow animate__animated animate__fadeIn" data-wow-delay=".5s">
                                                    <span className="banner-step banner-step-number2">02</span>
                                                    <img src={CiircleBg} className="banner-image banner-image-step2" alt="" />
                                                    <div class="Animations_Labirynth">
                                                        <img src={Labirynth1} className="position-absolute" alt="" id="frame1" /> 
                                                        <img src={Labirynth2} className="position-absolute" alt="" id="frame2" /> 
                                                        <img src={Labirynth3} className="position-absolute" alt="" id="frame3" /> 
                                                        <img src={Labirynth4} className="position-absolute" alt="" id="frame4" /> 
                                                        <img src={Labirynth5} className="position-absolute" alt="" id="frame5" /> 
                                                        <img src={Labirynth6} className="position-absolute" alt="" id="frame6" /> 
                                                        <img src={Labirynth7} className="position-absolute" alt="" id="frame7" /> 
                                                        <img src={Labirynth8} className="position-absolute" alt="" id="frame8" /> 
                                                    </div>
                                                    <h5 className="banner-heading banner-heading-step2">Problem Solving and Technical Assessment </h5>
                                                    <p className="banner-content banner-content-step2">
                                                        A quantifiable problem solving and technical assessment is administered to invite only talent to measure technical and business acumen.
                                                    </p>
                                                </div>
                                                {/* Step 3 */}
                                                <div className="wow animate__animated animate__fadeIn" data-wow-delay=".6s">
                                                    <span className="banner-step banner-step-number3">03</span> 
                                                    <img src={CiircleBg} className="banner-image banner-image-step3" alt="" />
                                                    <div class="Animations_Heads">
                                                        <img src={head1} className="position-absolute" alt="" id="frame1" /> 
                                                        <img src={head2} className="position-absolute" alt="" id="frame2" /> 
                                                        <img src={head3} className="position-absolute" alt="" id="frame3" /> 
                                                        <img src={head4} className="position-absolute" alt="" id="frame4" /> 
                                                        <img src={head5} className="position-absolute" alt="" id="frame5" /> 
                                                        <img src={head6} className="position-absolute" alt="" id="frame6" /> 
                                                        <img src={head7} className="position-absolute" alt="" id="frame7" /> 
                                                        <img src={head8} className="position-absolute" alt="" id="frame8" /> 
                                                        <img src={head9} className="position-absolute" alt="" id="frame9" /> 
                                                        <img src={head10} className="position-absolute" alt="" id="frame10" /> 
                                                    </div> 
                                                    <h5 className="banner-heading banner-heading-step3">CULTURE FIT TEST </h5>
                                                    <p className="banner-content banner-content-step3">
                                                        Our team meets with the potential CTO to ensure the alignment of their personality with your company culture.
                                                    </p>
                                                </div>
                                                {/* Step 4 */}
                                                <div className="wow animate__animated animate__fadeIn" data-wow-delay=".7s">
                                                    <span className="banner-step banner-step-number4">04</span>
                                                    <img src={CiircleBg} className="banner-image banner-image-step4" alt="" />
                                                    <div class="Animations_Code wow animate__animated animate__fadeIn" data-wow-delay=".7s">
                                                        <img src={code1} className="position-absolute" alt="" id="frame1" /> 
                                                        <img src={code2} className="position-absolute" alt="" id="frame2" /> 
                                                        <img src={code3} className="position-absolute" alt="" id="frame3" /> 
                                                        <img src={code4} className="position-absolute" alt="" id="frame4" /> 
                                                        <img src={code5} className="position-absolute" alt="" id="frame5" /> 
                                                        <img src={code6} className="position-absolute" alt="" id="frame6" /> 
                                                        <img src={code7} className="position-absolute" alt="" id="frame7" /> 
                                                        <img src={code6} className="position-absolute" alt="" id="frame8" />  
                                                    </div>
                                                    <h5 className="banner-heading banner-heading-step4">TEST PROJECT </h5>
                                                    <p className="banner-content banner-content-step4">
                                                        A test project is completed by the candidate and scored by our team to simulate a live project.
                                                    </p>
                                                </div>
                                                {/* Step 5 */}
                                                <div className="wow animate__animated animate__fadeIn" data-wow-delay=".8s">
                                                    <span className="banner-step banner-step-number5">05</span>
                                                    <img src={CiircleBg} className="banner-image banner-image-step5" alt="" />
                                                    <div className="AI">
                                                        <img src={Brain1} className="position-absolute" alt="" id="brain1"/> 
                                                        <img src={Brain2} className="position-absolute" alt="" id="brain2"/> 
                                                        <img src={Brain3} className="position-absolute" alt="" id="brain3"/> 
                                                        <img src={Brain4} className="position-absolute" alt="" id="brain4"/> 
                                                        <img src={Brain5} className="position-absolute" alt="" id="brain5"/> 
                                                        <img src={Brain6} className="position-absolute" alt="" id="brain6"/> 
                                                    </div>
                                                    <h5 className="banner-heading banner-heading-step5">On-going AI and Human Optimization</h5>
                                                    <p className="banner-content banner-content-step5">
                                                        The Trusted Dev Portal and internal team optimizes your software development recruitment life cycle with AI and machine learning algorithms. 
                                                    </p>
                                                </div>
                                                {/* Step 6 */}
                                                {/* <div className="wow animate__animated animate__fadeIn" data-wow-delay=".8s">
                                                    <span className="banner-step banner-step-number5">05</span>
                                                    <img src={CiircleBg} className="banner-image banner-image-step5" alt="" />
                                                    <div class="Animations_Ekierka">
                                                        <img src={Ekierka1} className="position-absolute" alt="" id="frame1"/> 
                                                        <img src={Ekierka2} className="position-absolute" alt="" id="frame2"/> 
                                                        <img src={Ekierka3} className="position-absolute" alt="" id="frame3"/> 
                                                        <img src={Ekierka4} className="position-absolute" alt="" id="frame4"/> 
                                                        <img src={Ekierka5} className="position-absolute" alt="" id="frame5"/> 
                                                        <img src={Ekierka6} className="position-absolute" alt="" id="frame6"/> 
                                                    </div>
                                                    <h5 className="banner-heading banner-heading-step5">Live Complex Project Simulation Assessment </h5>
                                                    <p className="banner-content banner-content-step5">
                                                        We simulate a complex live project with the potential CTO to assess their mission critical problem solving skills as it pertains to your company culture and processes.
                                                    </p>
                                                </div> */}
                                                <div className="wow animate__animated animate__fadeIn" data-wow-delay=".9s">
                                                    <div className="banner-center-heading">The Trusted Dev VETTING <span className="text-style">Methodology</span></div>
                                                </div>
                                                <img src={Semicircle} className="semicircle-img wow animate__faster animate__animated animate__fadeInUp" data-wow-delay=".2s" alt="" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="trusteddev-tab d-xl-none d-lg-none d-md-none d-sm-block">
                            <div className="mobile-section-wrapper overflowX">
                                <h2 className="The-TrustedDev-Vetting-Methodology">
                                    The Trusted Dev <span className="text-style-1">Vetting</span> <span className="text-style-2">Methodology</span>
                                </h2>
                                <div className="row no-gutters cto-step-section step-section">
                                    <div className="col-6">
                                        <div>
                                            <div className="padding0"> 
                                                    <img src={CiircleBg} className="icon_bg icon_bg1 wow animate__animated animate__bounceIn animation_delay-1point4" alt="" />
                                                    <div class="Animations_Card wow animate__animated animate__bounceIn animation_delay-1point4">
                                                        <img src={card1} className="position-absolute" alt="" id="frame1" /> 
                                                        <img src={card2} className="position-absolute" alt="" id="frame2" /> 
                                                        <img src={card3} className="position-absolute" alt="" id="frame3" /> 
                                                        <img src={card4} className="position-absolute" alt="" id="frame4" /> 
                                                        <img src={card5} className="position-absolute" alt="" id="frame5" /> 
                                                        <img src={card6} className="position-absolute" alt="" id="frame6" /> 
                                                        <img src={card7} className="position-absolute" alt="" id="frame7" /> 
                                                        <img src={card8} className="position-absolute" alt="" id="frame8" /> 
                                                    </div> 
                                                <span className="lineLeft_1 card_line wow animate__animated animate__faster animate__fadeInRightBig animation_delay-1point2">
                                                    <span className="digit digit_01">01</span>
                                                    <span className="blue_dot blue_dot_step1"></span>
                                                </span>
                                                <div className="wow animate__animated animate__fadeIn animation_delay-1point6">
                                                    <h3 className="Vetting-Methodology-step-heading color_heading">
                                                        Identify
                                                    </h3>
                                                    <p className="font14 The-TrustedDev-Vetting-Methodology-Card-Content">
                                                        We identify then vet top software engineering talent from a technical, creative problem solving, communication and tailored culture fit perspective.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={CiircleBg} className="icon_bg icon_bg3 wow animate__animated animate__bounceIn animation_delay-0point8" alt="" />
                                            <div class="Animations_Heads wow animate__animated animate__bounceIn animation_delay-0point8">
                                                <img src={head1} className="position-absolute" alt="" id="frame1" /> 
                                                <img src={head2} className="position-absolute" alt="" id="frame2" /> 
                                                <img src={head3} className="position-absolute" alt="" id="frame3" /> 
                                                <img src={head4} className="position-absolute" alt="" id="frame4" /> 
                                                <img src={head5} className="position-absolute" alt="" id="frame5" /> 
                                                <img src={head6} className="position-absolute" alt="" id="frame6" /> 
                                                <img src={head7} className="position-absolute" alt="" id="frame7" /> 
                                                <img src={head8} className="position-absolute" alt="" id="frame8" /> 
                                                <img src={head9} className="position-absolute" alt="" id="frame9" /> 
                                                <img src={head10} className="position-absolute" alt="" id="frame10" /> 
                                            </div> 
                                            <span className="lineLeft_3 card_line wow animate__animated animate__faster animate__fadeInRightBig animation_delay-0point5">
                                                <span className="digit digit_03">03</span>
                                                <span className="blue_dot blue_dot_step3"></span>
                                            </span>
                                            <div className="wow animate__animated animate__fadeIn animation_delay-1">
                                                <h3 className="Vetting-Methodology-step-heading color_heading animate__animated animate__zoomIn animate__delay-1s">Culture fit test</h3>
                                                <p className="font14 The-TrustedDev-Vetting-Methodology-Card-Content animate__animated animate__zoomIn animate__delay-1s">
                                                    Our team meets with the candidate to ensure alignment of their personality with your company culture.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-relative"> 
                                            <img src={CiircleBg} className="icon_bg icon_bg5 wow animate__bounceIn animate__animated animation_delay-1point6" alt="" />
                                            <div class="Animations_Ekierka wow animate__bounceIn animate__animated animation_delay-1point6">
                                                <img src={Ekierka1} className="position-absolute" alt="" id="frame1"/> 
                                                <img src={Ekierka2} className="position-absolute" alt="" id="frame2"/> 
                                                <img src={Ekierka3} className="position-absolute" alt="" id="frame3"/> 
                                                <img src={Ekierka4} className="position-absolute" alt="" id="frame4"/> 
                                                <img src={Ekierka5} className="position-absolute" alt="" id="frame5"/> 
                                                <img src={Ekierka6} className="position-absolute" alt="" id="frame6"/> 
                                            </div>
                                            <span className="lineLeft_5 card_line  wow animate__faster animate__fadeInLeftBig animate__animated animation_delay-1point6">
                                                <span className="digit digit_05">05</span>
                                            </span>
                                            <div className=" wow animate__faster animate__zoomIn animate__animated animation_delay-1point6">
                                                <h3 className="Vetting-Methodology-step-heading color_heading">On-Going AI and human optimization</h3>
                                                <p className="font14 The-TrustedDev-Vetting-Methodology-Card-Content">
                                                    The Trusted Dev Portal and internal team optimizes your software development recruitment life cycle with AI and machine learning algorithms.
                                                </p>
                                            </div>
                                            <span className="circle_blue_large circle_blue">
                                                <span className="circle_blue_medium circle_blue">
                                                    <span className="blue_dot blue_dot_step5"></span>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="dotted_line"></div>
                                    </div>
                                    <div className="col-6">
                                        <div className="position-relative">
                                            <img src={CiircleBg} className="icon_bg_right icon_bg2 wow animate__bounceIn animate__animated animation_delay-2" alt="" />
                                            <div class="Animations_Labirynth  wow animate__bounceIn animate__animated animation_delay-2">
                                                <img src={Labirynth1} className="position-absolute" alt="" id="frame1" /> 
                                                <img src={Labirynth2} className="position-absolute" alt="" id="frame2" /> 
                                                <img src={Labirynth3} className="position-absolute" alt="" id="frame3" /> 
                                                <img src={Labirynth4} className="position-absolute" alt="" id="frame4" /> 
                                                <img src={Labirynth5} className="position-absolute" alt="" id="frame5" /> 
                                                <img src={Labirynth6} className="position-absolute" alt="" id="frame6" /> 
                                                <img src={Labirynth7} className="position-absolute" alt="" id="frame7" /> 
                                                <img src={Labirynth8} className="position-absolute" alt="" id="frame8" /> 
                                            </div>
                                            <span className="lineRight_2 card_line wow animate__animated animate__faster animate__fadeInLeftBig animation_delay-1point8">
                                                <span className="digit digit_02">02</span>
                                                <span className="blue_dot blue_dot_step2"></span>
                                            </span>
                                            <div className="wow animate__animated animate__fadeIn animation_delay-2point2">
                                                <h3 className="Vetting-Methodology-step-heading color_heading">Problem solving and<br /> technical assessment</h3>
                                                <p className="font14 The-TrustedDev-Vetting-Methodology-Card-Content">
                                                    A quantifiable software development assessment is administered to invite only talent to ensure technical and creative problem solving proficiency based on your preferred tech stack.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-relative">
                                            <img src={CiircleBg} className="icon_bg_right icon_bg4 margin0 wow animate__bounceIn animate__animated animation_delay-1point4" alt="" />
                                            <div class="Animations_Code wow animate__bounceIn animate__animated animation_delay-1point4">
                                                <img src={code1} className="position-absolute" alt="" id="frame1" /> 
                                                <img src={code2} className="position-absolute" alt="" id="frame2" /> 
                                                <img src={code3} className="position-absolute" alt="" id="frame3" /> 
                                                <img src={code4} className="position-absolute" alt="" id="frame4" /> 
                                                <img src={code5} className="position-absolute" alt="" id="frame5" /> 
                                                <img src={code6} className="position-absolute" alt="" id="frame6" /> 
                                                <img src={code7} className="position-absolute" alt="" id="frame7" /> 
                                                <img src={code6} className="position-absolute" alt="" id="frame8" />  
                                            </div>
                                            <span className="lineRight_4 card_line wow animate__animated animate__faster animate__fadeInLeftBig animation_delay-1point2">
                                                <span className="digit digit_04">04</span>
                                                <span className="blue_dot blue_dot_step4"></span>
                                            </span>
                                            <div className="wow animate__animated animate__fadeIn animation_delay-1point6">
                                                <h3 className="Vetting-Methodology-step-heading color_heading">test project</h3>
                                                <p className="font14 The-TrustedDev-Vetting-Methodology-Card-Content">
                                                    A test project is completed by the candidate and scored by our team to simulate a live project.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters justify-content-start wow animate__animated animate__fadeInLeft" data-wow-delay=".2s">
                            <div className="col-md-10">
                                <div className="page-divider-section-background">
                                    <div className="row page-divider-section no-gutters">
                                        <div className="offset-md-3 col-md-9 d-flex justify-content-center align-items-center">
                                            <h3 className="Brands-That-Our-Leadership-content wow animate__animated animate__fadeIn" data-wow-delay=".4s">
                                                <span className="Brands-That-Our-Leadership-content Brands-That-Our-Leadership-content_brand-text">Brands </span>That Our Leadership Team Has Delivered <span className="Brands-That-Our-Leadership-content Brands-That-Our-Leadership-content_bold-text">Projects</span> To:
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters overflowX">
                            <div className="offset-md-2 col-md-8">
                                <div className="d-none d-md-block d-lg-block d-xl-block">
                                    <ul class="brands p-0">
                                        <li class="brands__item d-flex align-items-center justify-content-center">
                                            <img src={cocacola} className="wow animate__animated animate__fadeIn" data-wow-delay=".4s" alt="cocacola" />
                                        </li>
                                        <li class="brands__item d-flex align-items-center justify-content-center">
                                            <img src={kelloggs} className="wow animate__animated animate__fadeIn" data-wow-delay=".5s" alt="kelloggs" />
                                        </li>
                                        <li class="brands__item d-flex align-items-center justify-content-center">
                                            <img src={jpmorgan} className="wow animate__animated animate__fadeIn" data-wow-delay=".6s" alt="jpmorgan" />
                                        </li>
                                        <li class="brands__item d-flex align-items-center justify-content-center">
                                            <img src={charles} className="wow animate__animated animate__fadeIn" data-wow-delay=".7s" alt="charles" />
                                        </li>
                                        <li class="brands__item d-flex align-items-center justify-content-center">
                                            <img src={experian} className="wow animate__animated animate__fadeIn" data-wow-delay=".8s" alt="experian" />
                                        </li>
                                    </ul>
                                </div>
                                <div className="d-sm-block d-md-none d-lg-none d-xl-none cto-brand-logo-divider overflowX">
                                    <div className="row no-gutters d-flex align-items-center justify-content-around bg_white">
                                        <img src={cocacola} className="cocacola wow animate__animated animate__fadeIn" data-wow-delay=".4s" alt="cocacola" />
                                        <img src={kelloggs} className="kelloggs wow animate__animated animate__fadeIn" data-wow-delay=".5s" alt="kelloggs" />
                                        <img src={jpmorgan} className="jpmorgan wow animate__animated animate__fadeIn" data-wow-delay=".6s" alt="jpmorgan" />
                                    </div>
                                    <div className="row no-gutters d-flex align-items-center justify-content-center pb-3 bg_white">
                                        <img src={charles} className="charles wow animate__animated animate__fadeIn" data-wow-delay=".7s" alt="charles" />
                                        <img src={experian} className="experian wow animate__animated animate__fadeIn" data-wow-delay=".8s" alt="experian" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" overflowX">
                            <div className="row no-gutters justify-content-end wow animate__animated animate__fadeInRight" data-wow-delay=".9s">
                                <div className="col-md-10">
                                    <div className="dark_bar_page_divider">
                                        <div className="col-md-11">A dedicated customer success concierge is assigned providing VIP service for your mission critical project requirements.
                                            <h3 className="A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring wow animate__animated animate__fadeIn" data-wow-delay="1s">
                                                <span className="text-style-1">A dedicated</span> customer success <span className="text-style-1">Concierge</span> Works <br /> With Every Client to Ensure Campaign <span className="text-style-2">Success</span>
                                            </h3>
                                        </div>
                                        <div className="offset-md-1"> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="order-section overflowX">
                            <div className="row no-gutters">
                                <div className="col-md-12 step-section">
                                    {/* step 1 */}
                                    <img src={Step1} alt="" className="d-none d-lg-block d-xl-block step-img step-img-step1 wow animate__zoomIn animate__animated" data-wow-delay="0.2s" />
                                    <img src={Step1B} alt="" className="d-lg-none d-xl-none step-img step-img-step1 wow animate__zoomIn animate__animated" data-wow-delay="0.2s" />
                                    <span className="d-lg-none d-xl-none step-label step-label-step1 wow animate__fadeIn animate__animated" data-wow-delay=".2s">STEP</span>
                                    <p className="step-content step-content-step1 wow animate__fadeIn animate__animated" data-wow-delay="0.4s">
                                        You register an account to hire top CTO caliber talent.
                                    </p>
                                    <img src={Arrow1} alt="" className="step-arrow step-arrow-step1 wow animate__fadeInTopLeft animate__animated" data-wow-delay="0.3s" />
                                    <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step1 wow animate__fadeInRight animate__animated overflowX" data-wow-delay="0.3s" />

                                    {/* step 2 */}
                                    <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step2 wow animate__fadeInLeft animate__animated overflowX" data-wow-delay="0.6s" />
                                    <img src={Arrow2} alt="" className="step-arrow step-arrow-step2 wow animate__fadeInTopRight animate__animated" data-wow-delay="0.6s" />
                                    <span className="d-lg-none d-xl-none step-label step-label-step2 wow animate__fadeIn animate__animated" data-wow-delay=".6s">STEP</span>
                                    <p className="step-content step-content-step2 wow animate__fadeIn animate__animated" data-wow-delay="0.7s">
                                    A dedicated customer success concierge is assigned providing VIP service for your mission critical project requirements.
                                    </p>
                                    <img src={Step2} className="d-none d-lg-block d-xl-block step-img step-img-step2 wow animate__zoomIn animate__animated" data-wow-delay="0.5s" alt="" />
                                    <img src={Step2B} className="d-lg-none d-xl-none step-img step-img-step2 wow animate__zoomIn animate__animated" data-wow-delay="0.5s" alt="" />

                                    {/* step 3 */}
                                    <p className="step-content step-content-step3 wow animate__fadeIn animate__animated" data-wow-delay="1s">
                                        Working in conjunction with your customer success concierge we vet CTO caliber talent based on your hiring profile, culture fit and the nature of your most complex business and technical projects.
                                    </p>
                                    <span className="d-lg-none d-xl-none step-label step-label-step3 wow animate__fadeIn animate__animated" data-wow-delay="1s">STEP</span>
                                    <img src={Step3} className="step-img step-img-step3 wow animate__zoomIn animate__animated" data-wow-delay="0.8s" alt="" />
                                    <img src={Arrow3} className="step-arrow step-arrow-step3 wow animate__fadeInTopLeft animate__animated" data-wow-delay="0.9s" alt="" />
                                    <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step3 wow animate__fadeInLeft animate__animated overflowX" data-wow-delay="0.9s" />

                                    {/* step 4 */}
                                    <span className="d-lg-none d-xl-none step-label step-label-step4 wow animate__fadeIn animate__animated" data-wow-delay="1.3s">STEP</span>
                                    <img src={Step4} className="step-img step-img-step4 wow animate__zoomIn animate__animated" data-wow-delay="1.1s" alt="" />
                                    <img src={Mobile_Arrow} alt="" className="step-arrow mobile-step4 wow animate__fadeInLeft animate__animated" />
                                    <p className="step-content step-content-step4 wow animate__fadeIn animate__animated" data-wow-delay="1.2s">
                                        You determine the CTO to hire and they join your internal team.
                                    </p>
                                    <img src={Arrow4} className="step-arrow step-arrow-step4 wow animate__fadeInTopRight animate__animated" alt="" data-wow-delay="1.6s" />
                                    {/* step 5 */}
                                    <img src={Step5} className="step-img step-img-step5 wow animate__zoomIn animate__animated" alt="" data-wow-delay="1.7s" />
                                    <span className="step-label step-label-step5 wow animate__fadeIn animate__animated" data-wow-delay="1.8s">STEP</span>
                                    <p className="step-content step-content-step5 wow animate__fadeIn animate__animated" data-wow-delay="1.9s">
                                        We optimize your software development recruitment life cycle with AI, machine learning and the human touch.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="page_bottom_divider_banner">
                            <h3 className="Proud-Member-of-the-Following-Organizations p-4 wow animate__fadeIn animate__animated" data-wow-delay=".2s">Proud <span className="color_color_light_red">Member</span> of the Following Organizations:</h3>
                            <div className="row no-gutters justify-content-start wow animate__slideInLeft animate__faster animate__animated" data-wow-delay=".8s">
                                <div className="col-xl-10 col-lg-10 col-md-10 logo_divider_row d-none d-md-block d-lg-block d-xl-block">
                                    <div className="row justify-content-end">
                                        <div className="offset-xl-2 col-xl-push-8 offset-lg-3 col-lg-9  offset-md-3 col-md-9 d-flex justify-content-center align-items-center">
                                            <img src={shape_19} className="image image_PML_img wow animate__fadeIn animate__animated" data-wow-delay="1s" alt="" />
                                            <img src={shape_20} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.1s" alt="" />
                                            <img src={shape_21} className="image image_w150 wow animate__fadeIn animate__animated" data-wow-delay="1.2s" alt="" />
                                            <img src={shape_22} className="image image_w160 wow animate__fadeIn animate__animated" data-wow-delay="1.3s" alt="" />
                                            <img src={shape_23} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.4s" alt="" />
                                            <img src={shape_24} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.5s" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 logo_divider_row d-sm-block d-md-none d-lg-none d-xl-none">
                                    <div className="d-flex justify-content-around align-items-end">
                                        <img src={shape_19} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1s" alt="" />
                                        <img src={shape_20} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.1s" alt="" />
                                        <img src={shape_21} className="image image_w150 wow animate__fadeIn animate__animated" data-wow-delay="1.2s" alt="" />
                                    </div>
                                    <div className="d-flex justify-content-around align-items-end">
                                        <img src={shape_22} className="image image_w160 wow animate__fadeIn animate__animated" data-wow-delay="1.3s" alt="" />
                                        <img src={shape_23} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.4s" alt="" />
                                        <img src={shape_24} className="image image_w100 wow animate__fadeIn animate__animated" data-wow-delay="1.5s" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row no-gutters justify-content-start color_violet_light">
                                <div className="col-md-12 footer_block bg_cto_theme">
                                    <div className="container">
                                        <div className="row d-none d-md-block d-lg-block d-xl-block">
                                            <div className="col-md-12 padding0 mt-2 mb-4">
                                                <img src={site_logo_light} alt="" className="mobile-footer-logo" />
                                            </div>
                                        </div>
                                        <div className="row font14">
                                            <div className="col-md-6 padding0">
                                                <p className="footer-content color_violet_light">
                                                    Trusted Dev solves your biggest enterprise technical staff augmentation hiring challenges by leveraging machine learning with AI, passionate humans and over a decade of software development resource staffing experience. We move beyond traditional technical hiring and vetting methodologies by tailoring our approach to your business processes and company culture.
                                                </p>
                                                <div className="private-policy d-flex">
                                                    <label htmlFor="policy" className="pr-1 m-0 d-none d-md-block d-lg-block d-xl-block"><Link to={''}  >Privacy Policy</Link></label>
                                                    <ul className="pl-4 m-0 d-none d-md-block d-lg-block d-xl-block">
                                                        <li><Link to={''}  >Terms of Use</Link></li>
                                                    </ul>
                                                </div>
                                                <span className="copyrights color_violet_light d-none d-md-block d-lg-block d-xl-block">© TRUSTEDDEV 2020</span>
                                            </div>
                                            <div className="col-md-6 d-none d-md-block d-lg-block d-xl-block">
                                                <ul className="footer-nav color_violet_light">
                                                    <li>
                                                        <Link to={'/'}  >
                                                            Trusted Dev Portal
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={'cto'}  >
                                                            CTO as a Service Portal
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={'/'}  >
                                                            Home
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={'why'}  >
                                                            Why Us?
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={'ai'}  >
                                                            AI and Vetted Software Engineers
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={'engagement'}  >
                                                            Our Engagement Model
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={'customsaas'}  >
                                                            Enterprise Software Development
                                                        </Link>
                                                    </li>
                                                    <li className="color_violet_light">
                                                        <Link to={''}  >
                                                            Contact Us
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <div className="col-md-2 d-flex align-items-end  my-3"> 
                                                <div className="mx-2"> 
                                                    <a href="/#">
                                                        <img src={ violettwitterIcon } className="social-media" alt="" />
                                                    </a>
                                                </div>
                                                <div className="mx-2">
                                                    <a href="/#">
                                                        <img src={ violetfacebookIcon } className="social-media" alt="" />
                                                    </a>
                                                </div>
                                                <div className="mx-2">
                                                    <a href="/#">
                                                        <img src={ violetlinkedInIcon } className="social-media" alt="" />
                                                    </a>
                                                </div>
                                            </div>   */}
                                            <div className="d-inline margin-right">
                                                <div className="d-flex text-light font16">
                                                    <label htmlFor="policy" className="pr-1 d-xl-none d-lg-none d-md-none">Privacy Policy</label>
                                                    <ul className="pl-4 d-xl-none d-lg-none d-md-none">
                                                        <li>Terms of Use</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="font16 d-inline"><span className="d-xl-none d-lg-none d-md-none">© TRUSTEDDEV 2020</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(CTOPage);
