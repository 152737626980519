import React from 'react';
import siteLogo from '../../assets/images/site_logo_light.png';
import backArrow from '../../assets/images/back-arrow.svg';
import eye from '../../assets/images/Icon-awesome-eye.svg';
import eyeSlash from '../../assets/images/Icon-awesome-eye-slash.svg';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { withAlert } from 'react-alert';
import { CONSTANTS } from '../../constants';
import { handleRegistration, resetUserForm, updatedUserDetail } from '../../actions/basic-info';
import { storeInStorage } from '../../common/service';

const CreatePassSchema = Yup.object().shape({
    password: Yup.string().required('New password is required').matches(CONSTANTS.REGEX.PASSWORD, 'Password is invalid'),
    confirm_password: Yup.string().required('Confirm password is required').matches(CONSTANTS.REGEX.PASSWORD, 'Password is invalid').test('passwords-match', 'Both Passwords must match', function (value) {
        return this.parent.password === value
    })
});

class PasswordCreation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewPass: false,
            showConfirmPass: false,
            isLoading: false
        }
    }

    componentDidMount() {
        if (!(this.props.basicData.info && this.props.basicData.info.email)) {
            this.props.history.push('/basic-info');
        }
    }

    togglePassword(fieldToToggle) {
        if (fieldToToggle === 'showNewPass') {
            this.setState({ showNewPass: !this.state.showNewPass });
        } else {
            this.setState({ showConfirmPass: !this.state.showConfirmPass });
        }
    }

    handleCreate(values) {
        this.setState({ isLoading: true });
        console.log({ ...this.props.basicData });
        this.props.updatedUserDetail(values);
        console.log({ ...this.props.basicData });
        this.props.handleRegistration(this.props.basicData.info).then((isRegistered) => {
            if (isRegistered) {
                console.log(this.props.basicData);
                storeInStorage(CONSTANTS.STORAGE_KEYS.USER_INFO, JSON.stringify(this.props.basicData.info));
                this.props.resetUserForm();
                this.props.history.push('/questionnaire-one');
            } else {
                this.props.alert.show(this.props.basicData.message);
                this.setState({ isLoading: false });
            }
        });
    }

    goBack(){
        this.props.resetUserForm();
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="Password-Creation-Page">
                <div className="row no-gutters">
                    <div className="col-md-6 dark-bckground d-none d-md-block d-lg-block d-xl-block">
                        <img src={siteLogo} alt="" className="site-logo" />
                        <div className="blue-longdash"></div>
                        <h3 className="trustedDev-register-label">Requirements Personalization Survey</h3>
                        <div className="header-Banner"></div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12">
                        <div className="card basic-info-card">
                            <nav className="navbar navbar-dark">
                                <div className="d-flex progress-bar-wrapper">
                                    <buttton className="header-text btn btn-link p-0" onClick={() => {
                                        this.goBack();
                                    }}>
                                        <img src={backArrow} alt="" className="back-arrow" /> <span className="d-none d-md-block d-lg-block d-xl-block">Back to site</span>
                                    </buttton>
                                    <div className="d-flex">
                                        <div className="">
                                            <label className="progress-bar-label active-label">Basic info</label>
                                            <div className="progress progress-one">
                                                <div className="progress-bar progress-bar-active" role="progressbar" style={{ width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="progress-bar-label  inactive-label">Survey 1/2</label>
                                            <div className="progress progress-two">
                                                <div className="progress-bar progress-bar-inactive" role="progressbar" style={{ width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="progress-bar-label  inactive-label">Survey 2/2</label>
                                            <div className="progress progress-three">
                                                <div className="progress-bar progress-bar-inactive" role="progressbar" style={{ width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="progress-bar-label last-progress-bar"></label>
                                            <div className="progress progress-four">
                                                <div className="progress-bar progress-bar-inactive" role="progressbar" style={{ width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                            <div className="card-body-wrapper">
                                <div className="card-body">
                                    <Formik
                                        initialValues={this.props.basicData.info}
                                        validationSchema={CreatePassSchema}
                                        onSubmit={values => {
                                            // same shape as initial values
                                            console.log(values);
                                            this.handleCreate(values);
                                        }}
                                    >
                                        {({ handleSubmit, errors, touched, handleChange, handleBlur, values }) => (
                                            <form className="login-form password-creation-form d-flex justify-content-center" onSubmit={handleSubmit}>
                                                <div className="card-modal">
                                                    <h4 className="basic-form-headline">
                                                        Please create your password.
                                                    </h4>
                                                    <div className="form-group field_cont">
                                                        <label htmlFor="password" className="form-label">Password</label>
                                                        <input placeholder="Enter your password"
                                                            type={this.state.showNewPass ? 'text' : "password"} value={values.password}
                                                            onChange={(event) => {
                                                                handleChange(event);
                                                                this.props.updatedUserDetail({...values, password: event.target.value});
                                                            }} onBlur={handleBlur} name="password"
                                                            className={`form-control ${touched.password && errors.password ? 'invalid' : ''}`}
                                                        />
                                                        <img src={this.state.showNewPass ? eye : eyeSlash} alt="" className="eye-img" onClick={
                                                            () => {
                                                                this.togglePassword('showNewPass');
                                                            }
                                                        } />
                                                        {touched.password && errors.password ?
                                                            <span className="error_msg">{errors.password}</span>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="form-group field_cont margin-bottom50">
                                                        <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
                                                        <input placeholder="Enter your confirm password"
                                                            type={this.state.showConfirmPass ? 'text' : "password"} value={values.confirm_password}
                                                            onChange={(event) => {
                                                                handleChange(event);
                                                                this.props.updatedUserDetail({...values, confirm_password: event.target.value});
                                                            }} onBlur={handleBlur} name="confirm_password"
                                                            className={`form-control ${touched.confirm_password && errors.confirm_password ? 'invalid' : ''}`}
                                                        />
                                                        <img src={this.state.showConfirmPass ? eye : eyeSlash} alt="" className="eye-img" onClick={
                                                            () => {
                                                                this.togglePassword('showConfirmPass');
                                                            }
                                                        } />
                                                        {touched.confirm_password && errors.confirm_password ?
                                                            <span className="error_msg">{errors.confirm_password}</span>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="password-req-wrapper">
                                                        <span className="req-header">Password Requirements</span>
                                                        <div className="password-req-wrapper req-list">
                                                            <div className="password-req-wrapper list-item">• Be a minimum of eight (8) characters in length.</div>
                                                            <div className="password-req-wrapper list-item">• Contain at least one (1) character from three (3) of the following categories: Uppercase letter (A-Z) Lowercase letter (a-z) Digit (0-9) Special character #(~`! …</div>
                                                            <div className="password-req-wrapper list-item">• Be memorized; if a password is written down it must be secure.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <button type="button" className="btn continue-btn gray-btn" onClick={() => {
                                                        this.props.history.push('/basic-info');
                                                    }}
                                                        disabled={this.state.isLoading}
                                                    >
                                                        PREVIOUS STEP
                                                    </button>
                                                    <button type="submit" className="btn continue-btn"
                                                        disabled={this.state.isLoading}
                                                    >
                                                        {this.state.isLoading ?
                                                            <div className="spinner-grow spinner-grow-sm">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                            :
                                                            <span>CONTINUE {'>'}</span>
                                                        }
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    basicData: state.basicInfoData
});

const mapActionsToProps = {
    handleRegistration,
    updatedUserDetail,
    resetUserForm
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(withAlert()(PasswordCreation)));
