import React from 'react';
import siteLogo from '../../assets/images/site_logo_light.svg';
import backArrow from '../../assets/images/back-arrow.svg';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { withAlert } from 'react-alert';
import { handleLogDetails, resetUserForm, updatedUserDetail } from '../../actions/basic-info';
import { API_ENDPOINTS } from '../../constants/api-endpoints';
import { apiCall } from '../../common/NetworkCalls';
import { CONSTANTS } from '../../constants';

const BasicInfoSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required").matches(CONSTANTS.REGEX.NAME, 'Please enter valid first name'),
    last_name: Yup.string().required("Last Name is required").matches(CONSTANTS.REGEX.NAME, 'Please enter valid last name'),
    email: Yup.string().required("Email is required").email("Please enter valid email address"),
    phone_number: Yup.string().required("Phone number is required").matches(CONSTANTS.REGEX.PHONE, 'Please enter valid phone number'),
    company_name: Yup.string().required("Company name is required"),
    company_url: Yup.string().required("Company URL is required").matches(CONSTANTS.REGEX.URL, 'Please enter valid URL'),
    job_title: Yup.string().required("Job title is required"),
    terms: Yup.bool().oneOf([true], 'You must accept the terms of service')
});

class BasicInfoPage extends React.Component {

    componentDidMount() {
        window.onbeforeunload = confirmExit;
        function confirmExit() {
            return "Some task is in progress. Are you sure, you want to close?";
        }
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    handleUserData(key, value) {
        console.log(key, value);
        if (value) {
            var json = {};
            json[key] = value;
            this.props.updatedUserDetail(json);
            if (this.props.basicData.info && this.props.basicData.info.user_id) {
                return apiCall(API_ENDPOINTS.UPDATE_DETAILS, 'PUT', {
                    id: this.props.basicData.info.user_id,
                    key_name: key,
                    key_value: value
                }).then((response) => {
                    console.log(response);
                }).catch((error) => {
                    console.log(error);
                })
            } else {
                this.props.handleLogDetails({ key_name: key, key_value: value }).then((submitted) => {
                    console.log(submitted);
                })
            }
        }
    }

    goBack(){
        this.props.resetUserForm();
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="BasicIngo-Page">
                <div className="row no-gutters">
                    <div className="col-md-6 dark-bckground d-none d-md-block d-lg-block d-xl-block">
                        <img src={siteLogo} alt="" className="site-logo" />
                        <div className="blue-longdash"></div>
                        <h3 className="trustedDev-register-label">TrustedDev Account Registration</h3>
                        <div className="Banner"></div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12">
                        <div className="card basic-info-card">
                            <nav className="navbar navbar-dark">
                                <buttton className="header-text btn btn-link p-0" onClick={() => {
                                    this.goBack();
                                }}>
                                    <img src={backArrow} alt="" className="back-arrow" /> <span className="d-none d-md-block d-lg-block d-xl-block">Back to site</span>
                                </buttton>
                                <span className="header-text d-none d-md-block d-lg-block d-xl-block">I already have an account. <Link to={"/login"} className="sign-in">Sign in.</Link></span>
                            </nav>
                            <div className="card-body-wrapper">
                                <div className="card-body">
                                    <Formik
                                        initialValues={this.props.basicData.info}
                                        validationSchema={BasicInfoSchema}
                                        onSubmit={values => {
                                            // same shape as initial values
                                            console.log(values);
                                            this.props.updatedUserDetail(values);
                                            this.props.history.push("/password-creation");
                                        }}
                                    >
                                        {({ handleSubmit, errors, touched, handleChange, handleBlur, values }) => (
                                            <form className="basic-info-form" onSubmit={handleSubmit}>
                                                <div className="heading-wrapper">
                                                    <h4 className="basic-form-headline">Please fill out the form below to get started.</h4>
                                                </div>
                                                <div className="form-fields-wrapper">
                                                    <div className={`form-group ${touched.first_name && errors.first_name ? 'mb-0' : 'mb-3'}`}>
                                                        <label htmlFor="first_name" className="form-label">First Name</label>
                                                        <input type="text" name="first_name" placeholder="Enter your first name"
                                                            onChange={handleChange} onBlur={(event) => {
                                                                handleBlur(event);
                                                                this.handleUserData('first_name', values.first_name)
                                                            }} value={values.first_name}
                                                            className={`form-control ${touched.first_name && errors.first_name ? 'invalid' : ''}`}
                                                        />
                                                        {touched.first_name && errors.first_name ?
                                                            <span className="error_msg">{errors.first_name}</span>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className={`form-group ${touched.last_name && errors.last_name ? 'mb-0' : 'mb-3'}`}>
                                                        <label htmlFor="last_name" className="form-label">Last Name</label>
                                                        <input type="text" name="last_name" placeholder="Enter your  last name"
                                                            onChange={handleChange} onBlur={(event) => {
                                                                handleBlur(event);
                                                                this.handleUserData('last_name', values.last_name)
                                                            }} value={values.last_name}
                                                            className={`form-control ${touched.last_name && errors.last_name ? 'invalid' : ''}`}
                                                        />
                                                        {touched.last_name && errors.last_name ?
                                                            <span className="error_msg">{errors.last_name}</span>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className={`form-group ${touched.phone_number && errors.phone_number ? 'mb-0' : 'mb-3'}`}>
                                                        <label htmlFor="phone_number" className="form-label">Phone Number</label>
                                                        <input type="number" name="phone_number" placeholder="Enter your  phone number"
                                                            onChange={handleChange} onBlur={(event) => {
                                                                handleBlur(event);
                                                                this.handleUserData('phone_number', values.phone_number)
                                                            }} value={values.phone_number}
                                                            className={`form-control ${touched.phone_number && errors.phone_number ? 'invalid' : ''}`}
                                                        />
                                                        {touched.phone_number && errors.phone_number ?
                                                            <span className="error_msg">{errors.phone_number}</span>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className={`form-group ${touched.company_name && errors.company_name ? 'mb-0' : 'mb-3'}`}>
                                                        <label htmlFor="company_name" className="form-label">Company Name</label>
                                                        <input type="text" name="company_name" placeholder="Enter name of your company"
                                                            onChange={handleChange} onBlur={(event) => {
                                                                handleBlur(event);
                                                                this.handleUserData('company_name', values.company_name)
                                                            }} value={values.company_name}
                                                            className={`form-control ${touched.company_name && errors.company_name ? 'invalid' : ''}`}
                                                        />
                                                        {touched.company_name && errors.company_name ?
                                                            <span className="error_msg">{errors.company_name}</span>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className={`form-group ${touched.company_url && errors.company_url ? 'mb-0' : 'mb-3'}`}>
                                                        <label htmlFor="company_url" className="form-label">Company URL</label>
                                                        <input type="text" name="company_url" placeholder="Enter your company url"
                                                            onChange={handleChange} onBlur={(event) => {
                                                                handleBlur(event);
                                                                this.handleUserData('company_url', values.company_url)
                                                            }} value={values.company_url}
                                                            className={`form-control ${touched.company_url && errors.company_url ? 'invalid' : ''}`}
                                                        />
                                                        {touched.company_url && errors.company_url ?
                                                            <span className="error_msg">{errors.company_url}</span>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className={`form-group ${touched.job_title && errors.job_title ? 'mb-0' : 'mb-3'}`}>
                                                        <label htmlFor="job_title" className="form-label">Job Title</label>
                                                        <input type="text" name="job_title" placeholder="Enter your  job title"
                                                            onChange={handleChange} onBlur={(event) => {
                                                                handleBlur(event);
                                                                this.handleUserData('job_title', values.job_title)
                                                            }} value={values.job_title}
                                                            className={`form-control ${touched.job_title && errors.job_title ? 'invalid' : ''}`}
                                                        />
                                                        {touched.job_title && errors.job_title ?
                                                            <span className="error_msg">{errors.job_title}</span>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className={`form-group ${touched.email && errors.email ? 'mb-0' : 'mb-3'}`}>
                                                        <label htmlFor="email" className="form-label">Email Address</label>
                                                        <input type="text" name="email" autoComplete="off" placeholder="Enter your  email address"
                                                            onChange={handleChange} onBlur={(event) => {
                                                                handleBlur(event);
                                                                this.handleUserData('email', values.email)
                                                            }} value={values.email}
                                                            className={`form-control ${touched.email && errors.email ? 'invalid' : ''}`}
                                                        />
                                                        {touched.email && errors.email ?
                                                            <span className="error_msg">{errors.email}</span>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="input-group mb-4 mt-1">
                                                        <div className="d-flex align-items-baseline w-100">
                                                            <div className="position-relative">
                                                                <div className="checkbox-container circular-container">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="terms" onChange={handleChange} onBlur={handleBlur}
                                                                            checked={values.terms}
                                                                        />
                                                                        <span className="checkbox-custom circular"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <span className="checkbox-text">I accept the <u>Terms of Service</u> and <u>Privacy Policy</u></span>
                                                        </div>
                                                        {touched.terms && errors.terms ?
                                                            <span className="error_msg mt-2">{errors.terms}</span>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="card-footer d-flex justify-content-center">
                                                    <button type="submit" className="btn continue-btn">CONTINUE {'>'}</button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    basicData: state.basicInfoData
});

const mapActionsToProps = {
    updatedUserDetail,
    handleLogDetails,
    resetUserForm
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(withAlert()(BasicInfoPage)));
