import React from 'react';
import site_logo_light from '../assets/images/site_logo_light.png';
import { Link } from 'react-router-dom';
// import twitter from '../assets/images/shape-16.png';
// import facebook from '../assets/images/shape-17.png';
// import linkedIn from '../assets/images/shape-18.png';

const Footer = (props) => { 
    return (
            <div> 
                <div className="row no-gutters justify-content-start color_color2">
                    <div className="col-md-12 footer_block bg_blue_footer">
                        <div className="container">
                            <div className="row d-none d-md-block d-lg-block d-xl-block">
                                <div className="col-md-12 padding0 mt-2 mb-4">
                                    <img src={ site_logo_light } alt="" className="mobile-footer-logo"/>
                                </div>
                            </div>
                            <div className="row font14">
                                <div className="col-md-6 padding0">
                                    <p className="footer-content color_blue_light">
                                        Trusted Dev solves your biggest enterprise technical staff augmentation hiring challenges by leveraging machine learning with AI, passionate humans and over a decade of software development resource staffing experience. We move beyond traditional technical hiring and vetting methodologies by tailoring our approach to your business processes and company culture. 
                                    </p> 
                                    <div className="private-policy d-flex">
                                        <label htmlFor="policy" className="pr-1 m-0 d-none d-md-block d-lg-block d-xl-block"><Link to={''}  >Privacy Policy</Link></label>
                                        <ul className="pl-4 m-0 d-none d-md-block d-lg-block d-xl-block">
                                            <li><Link to={''}  >Terms of Use</Link></li>
                                        </ul>
                                    </div> 
                                    <span className="copyrights color_blue_light d-none d-md-block d-lg-block d-xl-block">Trusted Dev 2021</span>
                                </div>
                                <div className="col-md-6 d-none d-md-block d-lg-block d-xl-block"> 
                                    <ul className="footer-nav color_blue_light">
                                        <li>
                                            <Link to={'/'}  >
                                                Home
                                            </Link>        
                                        </li>
                                        <li>
                                            <Link to={'why'}  >
                                                Why Us?
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'ai'}  >
                                                AI and Vetted Software Engineering Talent
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'engagement'}  >
                                                Our Engagement Model
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'customsaas'}  >
                                                Enterprise Software Development
                                            </Link>
                                        </li>
                                    </ul>
                                </div> 
                                {/* <div className="col-md-2 d-flex align-items-end  my-3"> 
                                    <div className="mx-2"> 
                                        <a href="/#">
                                            <img src={ twitter } className="social-media" alt="" />
                                        </a>
                                    </div>
                                    <div className="mx-2">
                                        <a href="/#">
                                            <img src={ facebook } className="social-media" alt="" />
                                        </a>
                                    </div>
                                    <div className="mx-2">
                                        <a href="/#">
                                            <img src={ linkedIn } className="social-media" alt="" />
                                        </a>
                                    </div>
                                </div>   */}
                                <div className="d-inline margin-right"> 
                                    <div className="d-flex text-light font16">
                                        <label htmlFor="policy" className="pr-1 d-xl-none d-lg-none d-md-none">Privacy Policy</label>
                                        <ul className="pl-4 d-xl-none d-lg-none d-md-none">
                                            <li>Terms of Use</li>
                                        </ul>
                                    </div> 
                                </div>
                                <div className="font16 d-inline color_blue_light"><span className="d-xl-none d-lg-none d-md-none">© TRUSTEDDEV 2020</span></div> 
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
        ) 
} 

export default Footer;
